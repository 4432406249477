import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import MaterialSnackbar from "../Snackbar/Snackbar";
import { firmStatus } from "../../utils/Constants";
import { useSelector } from "react-redux";
const drawerWidth = 240;
const drawerCloseWidth = 68;

const useStyles = makeStyles((theme) => ({
  root: { 
    minHeight: "100vh",
    overflow: "hidden",
  },
  mainContentWithDrawerOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    position: "relative",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  mainContentWithDrawerClose: {
    width: `calc(100% - ${drawerCloseWidth}px)`,
    marginLeft: drawerCloseWidth,
    position: "relative",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

function MainLayout(props) {
  const classes = useStyles();
  const {
    drawer: { isOpen },
    auth: { user, expired },
    breadcrumbs: { generalBreadcrumbs }
  } = useSelector((state) => state);

  return (
    <div className={`${classes.root}`}>
      <main
        className={`${ (user?.firm?.status == firmStatus[0].active && user.firm.expired_at) ? '' : Object.keys(generalBreadcrumbs)?.length ? 'marginTop120' : 'marginTop60'} ${classes.mainContent} ${
          isOpen
            ? classes.mainContentWithDrawerOpen
            : classes.mainContentWithDrawerClose
        } p-4 `}
      >
        <MaterialSnackbar />
        {props.children}
      </main>
    </div>
  );
}

export default injectIntl(MainLayout);
