// Replace with your request types
export const PARTIES_LIST_REQUEST = "PARTIES_LIST_REQUEST";
export const PARTIES_LIST_RECIEVE = "PARTIES_LIST_RECIEVE";
//create
export const CASE_PARTIES_REQUEST = "CASE_PARTIES_REQUEST";
//get parties and update
export const GET_PARTIES = "GET_PARTIES";
export const ADD_PARTIES = "ADD_PARTIES";
export const UPDATE_PARTIES = "UPDATE_PARTIES";
export const DELETE_PARTIES = "DELETE_PARTIES";
