// Replace with your request types
export const CASE_CLASSIFICATIONS_REQUEST = "CASE_CLASSIFICATIONS_REQUEST";
export const CASE_CLASSIFICATIONS_RECIEVE = "CASE_CLASSIFICATIONS_RECIEVE";

export const CASE_TYPES_REQUEST = "CASE_TYPES_REQUEST";
export const CASE_TYPES_RECIEVE = "CASE_TYPES_RECIEVE";

export const CASE_STATUS_REQUEST = "CASE_STATUS_REQUEST";
export const CASE_STATUS_RECIEVE = "CASE_STATUS_RECIEVE";

export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST";
export const COUNTRIES_RECIEVE = "COUNTRIES_RECIEVE";

export const CITIES_REQUEST = "CITIES_REQUEST";
export const CITIES_RECIEVE = "CITIES_RECIEVE";

export const COUNTRIES_UNAUTHENTICATED_REQUEST = "COUNTRIES_UNAUTHENTICATED_REQUEST";
export const COUNTRIES_UNAUTHENTICATED_RECIEVE = "COUNTRIES_UNAUTHENTICATED_RECIEVE";

export const CITIES_FOR_SINGLE_ADD_REQUEST = "CITIES_FOR_SINGLE_ADD_REQUEST";
export const CITIES_FOR_SINGLE_ADD_RECIEVE = "CITIES_FOR_SINGLE_ADD_RECIEVE";

export const COURTS_REQUEST = "COURTS_REQUEST";
export const COURTS_RECIEVE = "COURTS_RECIEVE";

export const CIRCLES_REQUEST = "CIRCLES_REQUEST";
export const CIRCLES_RECIEVE = "CIRCLES_RECIEVE";

export const COURT_DEGREES_REQUEST = "COURT_DEGREES_REQUEST";
export const COURT_DEGREES_RECIEVE = "COURT_DEGREES_RECIEVE";

export const FIRM_NAMES_REQUEST = "FIRM_NAMES_REQUEST";
export const FIRM_NAMES_RECIEVE = "FIRM_NAMES_RECIEVE";

export const JOB_TITLES_REQUEST = "JOB_TITLES_REQUEST";
export const JOB_TITLES_RECIEVE = "JOB_TITLES_RECIEVE";

export const CREATE_JOB_TITLE = "CREATE_JOB_TITLE";

export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_RECIEVE = "ALL_USERS_RECIEVE";

export const ALL_CLIENTS_REQUEST = "ALL_CLIENTS_REQUEST";
export const ALL_CLIENTS_RECIEVE = "ALL_CLIENTS_RECIEVE";

export const ALL_CASE_MEMBERS_REQUEST = "ALL_CASE_MEMBERS_REQUEST";
export const ALL_CASE_MEMBERS_RECIEVE = "ALL_CASE_MEMBERS_RECIEVE";

export const GET_MEMBERS_OF_SPECIFIC_CASES_REQUEST = "GET_MEMBERS_OF_SPECIFIC_CASES_REQUEST";
export const GET_MEMBERS_OF_SPECIFIC_CASES_RECIEVE = "GET_MEMBERS_OF_SPECIFIC_CASES_RECIEVE";

export const GET_MEMBERS_OF_ALL_CASES_REQUEST = "GET_MEMBERS_OF_ALL_CASES_REQUEST";
export const GET_MEMBERS_OF_ALL_CASES_RECIEVE = "GET_MEMBERS_OF_ALL_CASES_RECIEVE";

export const ALL_CASE_MANAGER_REQUEST = "ALL_CASE_MANAGER_REQUEST";
export const ALL_CASE_MANAGER_RECIEVE = "ALL_CASE_MANAGER_RECIEVE";

export const TASK_CATEGORIES_REQUEST = "TASK_CATEGORIES_REQUEST";
export const TASK_CATEGORIES_RECIEVE = "TASK_CATEGORIES_RECIEVE";

export const EVENT_TYPES_REQUEST = "EVENT_TYPES_REQUEST";
export const EVENT_TYPES_RECIEVE = "EVENT_TYPES_RECIEVE";
//relations
export const RELATIONS_REQUEST = "RELATIONS_REQUEST";
export const RELATIONS_RECIEVE = "RELATIONS_RECIEVE";
export const CREATE_RELATION_TITLE = "CREATE_RELATION_TITLE";

export const DECISION_TYPES_REQUEST = "DECISION_TYPES_REQUEST";
export const DECISION_TYPES_RECIEVE = "DECISION_TYPES_RECIEVE";

export const TIMEZONES_REQUEST = "TIMEZONES_REQUEST";
export const TIMEZONES_RECIEVE = "TIMEZONES_RECIEVE";

export const MAIN_CLASS_CLASSIFICATION_RECIEVE = "MAIN_CLASS_CLASSIFICATION_RECIEVE";
export const ALL_SUB_CLASSES_CLASSIFICATION_RECIEVE = "ALL_SUB_CLASSES_CLASSIFICATION_RECIEVE";
export const ALL_CLASSES_OF_SUB_CLASSES_CLASSIFICATION_RECIEVE = "ALL_CLASSES_OF_SUB_CLASSES_CLASSIFICATION_RECIEVE";

export const LEGAL_LIBRARY_REQUEST = "LEGAL_LIBRARY_REQUEST";
export const LEGAL_LIBRARY_RECIEVE = "LEGAL_LIBRARY_RECIEVE";

//case classification
export const CASE_MAIN_CLASSIFICATION_REQUEST = "CASE_MAIN_CLASSIFICATION_REQUEST";
export const CASE_MAIN_CLASSIFICATION_RECIEVE = "CASE_MAIN_CLASSIFICATION_RECIEVE";

export const CASE_SUB_CLASSIFICATION_REQUEST = "CASE_SUB_CLASSIFICATION_REQUEST";
export const CASE_SUB_CLASSIFICATION_RECIEVE = "CASE_SUB_CLASSIFICATION_RECIEVE";

export const CASE_CLASSIFICATION_REQUEST = "CASE_CLASSIFICATION_REQUEST";
export const CASE_CLASSIFICATION_RECIEVE = "CASE_CLASSIFICATION_RECIEVE";

export const STATUS_REQUEST = "STATUS_REQUEST";
export const STATUS_RECIEVE = "STATUS_RECIEVE";

export const ALL_CONTACTS_REQUEST = "ALL_CONTACTS_REQUEST";
export const ALL_CONTACTS_RECIEVE = "ALL_CONTACTS_RECIEVE";

export const ALL_PARTIES_REQUEST = "ALL_PARTIES_REQUEST";
export const ALL_PARTIES_RECIEVE = "ALL_PARTIES_RECIEVE";

export const PARTY_TYPE_REQUEST = "PARTY_TYPE_REQUEST";
export const PARTY_TYPE_RECIEVE = "PARTY_TYPE_RECIEVE";

export const PARTY_CHARACTER_REQUEST = "PARTY_CHARACTER_REQUEST";
export const PARTY_CHARACTER_RECIEVE = "PARTY_CHARACTER_RECIEVE";

export const IDENTIFICATION_REQUEST = "IDENTIFICATION_REQUEST";
export const IDENTIFICATION_RECIEVE = "IDENTIFICATION_RECIEVE";

export const IDENTIFICATION_UNAUTHENTICATED_REQUEST = "IDENTIFICATION_UNAUTHENTICATED_REQUEST";
export const IDENTIFICATION_UNAUTHENTICATED_RECIEVE = "IDENTIFICATION_UNAUTHENTICATED_RECIEVE";

export const NATIONALITY_REQUEST = "NATIONALITY_REQUEST";
export const NATIONALITY_RECIEVE = "NATIONALITY_RECIEVE";

export const COMPANY_TYPE_REQUEST = "COMPANY_TYPE_REQUEST";
export const COMPANY_TYPE_RECIEVE = "COMPANY_TYPE_RECIEVE";

export const SESSION_TYPE_REQUEST = "SESSION_TYPE_REQUEST";
export const SESSION_TYPE_RECIEVE = "SESSION_TYPE_RECIEVE";

export const SESSION_OUTCOME_TYPE_REQUEST = "SESSION_OUTCOME_TYPE_REQUEST";
export const SESSION_OUTCOME_TYPE_RECIEVE = "SESSION_OUTCOME_TYPE_RECIEVE";

export const DISPLAY_VIEW_IN_SING_TAB = "DISPLAY_VIEW_IN_SING_TAB";

export const DISPLAY_VIEW_IN_LANDING_PAGE = "DISPLAY_VIEW_IN_LANDING_PAGE";

export const PRINT_TABLE_DATA = "PRINT_TABLE_DATA";

export const LIST_FILTER_VALUES = 'LIST_FILTER_VALUES';

export const SESSION_EXPIRASSION_POPUP_IS_OPEN = 'SESSION_EXPIRASSION_POPUP_IS_OPEN';

export const EXPORT_EXCEL_TEMPLATE_REQUEST = 'EXPORT_EXCEL_TEMPLATE_REQUEST'

export const IMPORT_EXCEL_TEMPLATE_REQUEST = 'IMPORT_EXCEL_TEMPLATE_REQUEST'
export const IMPORT_EXCEL_TEMPLATE_RESPONSE = 'IMPORT_EXCEL_TEMPLATE_RESPONSE'

export const NABOURHOOD_REQUEST = 'NABOURHOOD_REQUEST'
export const NABOURHOOD_RESPONSE = 'NABOURHOOD_RESPONSE'

