/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    hello: "Hello",
    navbar: {
      dashboard: "Dashboard",
      cases: "Cases",
      users: "User Management",
      revisions: "Revisions",
      contracts: "Contracts",
      settings: "Settings",
    },
    errors: {
      errorOccurred: "Error Occurred!",
      invalidEmail: "Invalid Email",
      required: "Required Field",
      passwordMismatch: "Password Mismatch",
      invalidImageType: "Invalid image type",
      invalidImageSize: "Image size is greater than 2MB",
      invalidPhoneFormat: "Phone number is not valid",
      invalidVerificationCode: "Verification code is not correct",
      passwordMinLength: "Password should not be less than 8 characters",
      passwordMaxLength: "Password should not be more than 128 characters",
      logoUploadFailed: "Logo upload failed",
      noImageFound: "No image found",
      onlyNumbers: "Must contain numbers only",
      maxLength250: "Max length of 250 characters",
      maxFileSize: "Max file size is 2MB",
      maxLength75: "Max length of 75 characters",
      maxLength100: "Max length of 100 characters",
      maxLength300: "Max length of 300 characters",
      maxNumbers3: "Max of 3 digits",
      maxNumbers5: "Max of 5 digits",
      onlyArabic: "Arabic characters only",
      fileType: "File type is not correct",
      expiredLink:
        "Sorry this link is expired. Redirecting to home page in 3 seconds",
      networkError: "No internet connection",
      imageRestrictions: "Max Size: 2 MB. Allowed types: png, jpeg, jpg",
      fileRestrictions:
        "Max Size: 2 MB. Allowed types: png, jpeg, jpg, doc, docx, xlsx, xls, pdf",
      isInvalid: "is invalid",
      minEndDate: "End date should not be less than start date",
    },
    controls: {
      emailAddress: "Email Address",
      password: "Password",
      confirmPassword: "Confirm password",
      phoneNumber: "Phone number",
      verificationCode: "Verification code",
      legalFirmName: "Legal firm name",
      adminEmail: "Admin email",
      subdomain: "Subdomain",
      nationality: "Nationality",
      identityType: "Identity type",
      notes: "Notes",
      rememberMe: "Remember me",
      clientName: "Client name",
      companyName: "Company name",
      companyAddress: "Company address",
      birthDate: "Birth date",
      caseID: "Case ID",
      court: "Court",
      caseDate: "Case date",
      circleNameNum: "Circle name and number",
      address: "Address",
      caseName: "Case name",
      category: "Category",
      type: "Type",
      caseSummary: "Case summary",
      twitter: "Twitter",
      facebook: "Facebook",
      socialNetworks: "Social networks",
      attachments: "Attachments",
      authorization: "Authorization",
      caseDetails: "Case details",
      courtDegree: "Court degree",
      name: "Name",
      brief: "Brief",
      role: "Role",
      jobTitle: "Job title",
      jobTitleEn: "Title in english",
      jobTitleAr: "Title in arabic",
      username: "Username",
      employeeName: "Employee name",
      createdBy: "Created by",
      addedAt: "Added at",
      dueDate: "Due date",
      title: "Title",
      description: "Description",
      userStatus: "User status",
      startDate: "Start date",
      endDate: "End date",
      attendees: "Attendees",
      guests: "Guests",
      eventLocation: "Event location",
      locationDetails: "Location details",
      reminder: "Reminder",
      sendReminderVia: "Send reminder via",
      interval: "Interval",
      assignee: "Assignee",
      eventStatus: "Event status",
      eventDate: "Event date",
      commercialRegistrationNo: "Commercial Registration",
      vatRegistrationNo: "vatRegistration",
      clientType: "Client type",
    },
    login: {
      signIn: "Sign in",
      welcomeTo: "Welcome to the",
      professionalDashboardForLaw: "Professional Dashboard for Law",
      findThousandCases:
        "Find thousand cases and ready to level up your careers.",
      didntRecieveCode: "Didn't recieve code ?",
      resendCode: "Resend code",
      setPassword: "Set Password",
      uploadLogo: "Upload Firm logo",
      forgotPassword: "Forgot password ?",
    },
    cases: {
      newCase: "New case",
      clientInfo: "Client details",
      caseInfo: "Case details",
      caseHistory: "Case history",
      suitParties: "Suit parties",
      teamMembers: "Team members",
      caseTeam: "Case team",
      allUsers: "All users",
      contacts: "Contacts",
      createNewCase: "Create new case",
      claimantsList: "Claimants list",
      defendantsList: "Defendants list",
      plaintiff: "Claimant",
      defendant: "Defendant",
      suitPartyType: "Suit party type",
      client: "Client",
      defendantType: "Defendant",
      plaintiffType: "Plaintiff",
      chooseClientType: "Choose client type",
      choosePartyType: "Choose party type",
      people: "People",
      companies: "Companies",
      companyInfo: "Company info",
      communicationRep: "Communication Representative",
      previousCaseHistory: "Previous case history",
      suitSubject: "Suit subject",
      suitRequests: "Suit requests",
      suitGrounds: "Suit grounds",
      appointments: "Appointments",
      upcomingAppointments: "Upcoming appointments",
      todayAppointment: "Today's appointments",
      finishedAppointments: "Finished appointments",
      tasksList: "Tasks list",
      currentTasks: "Current tasks",
      todayTasks: "Today's tasks",
      lateTasks: "Overdue tasks",
      completedTasks: "Completed tasks",
      casesSummary: "Cases summary",
      allCases: "All cases",
      case: "{count, plural, =0 {# Cases} one {# Case} other {# Cases}}",
      noCasesFound: "No cases found",
      generalInfo: "General info",
    },
    tasks: {
      tasks: "Tasks",
      task: "{count, plural, =0 {# Tasks} one {# Task} other {# Tasks}}",
      newAssigneeWarning:
        "The assignee is not part of the case team yet, but upon saving the user will be able to view the case details",
      createTask: "Create task",
      current: "Current",
      completed: "Completed",
      overdue: "Overdue",
      noDue: "No due date",
      noAssignee: "Unassigned",
      assignedTo: "Assigned to",
      noTasksFound: "No tasks found",
      completedAt: "Completed at",
      completedBy: "Completed by",
      tasksNotCompletedYet: "tasks not completed yet",
      changedTaskDateWarning:
        "The task due date has been modified, we recommend that you review your reminders",
    },
    teamMembers: {
      person: "{count, plural, =0 {# People} one {# Person} other {# People}}",
    },
    events: {
      events: "Events",
      event: "{count, plural, =0 {# Events} one {# Event} other {# Events}}",
      createEvent: "Create event",
      reminders: "Reminders",
      sentByEmail: "Sent by email",
      sentByNotification: "Sent by notification",
      changedEventDateWarning:
        "The event date has been modified, we recommend that you review your reminders",
      remindAttendeesWarning:
        "Event participants will be automatically reminded of the following dates",
      reminderNotActivatedWarning:
        "The alarm cannot be activated on an earlier date, it will be activated only for future date",
      reminder: "Reminder",
      noEventsFound: "No events found",
      upcoming: "Upcoming",
      current: "Current",
      ended: "Ended",
      allEvents: "All events",
      myEventsOnly: "My events only",
      upcomingEvent: "Upcoming event",
      lastEvent: "Last event",
      eventsNotCompletedYet:"Tasks not completed yet"
    },
    notifications: {
      assignedToTask: "You have been assigned a task",
      addedToEvent: "You have been added to an event",
      addedToTeam: "You have been added to the case team",
      noNotifications: "No notifications",
      reminder: "Reminder",
      event: "Event",
      task: "Task",
    },
    users: {
      newUser: "New user",
      addNewUser: "Add new user",
      manageUsers:"’Manage users",
      allUsers: "All users",
      users: "{count, plural, =0 {# Users} one {# User} other {# Users}}",
      noUsersFound: "No users found",
      status: "Status",
      inactive: "Inactive",
      active: "Active",
      suspended: "Suspended",
      expired: "Expired",
      addJobTitle: "Add job title",
      assignedAt: "Assigned at",
      activatedOn: "Activated on",
      suspendedOn: "Suspended on",
      expiredOn: "Expired on",
      reactivatedOn: "Reactivated on",
      activeAccount: "Active account",
      inactiveAccount: "Inactive account",
      suspendedAccount: "Suspended account",
      expiredAccount: "Expired account",
      permissions: "Permissions",
      addedBy: "Added by",
      addedAt: "Added at",
      searchInUsers: "Search in users",
      all: "All",
      current: "Current",
      removed: "Removed",
      last_login:"Last Login datetime"
    },
    addFirm: {
      addNewFirm: "Add new law firm",
      newFirm: "New firm",
    },
    actions: {
      add: "Add",
      logout: "Logout",
      upload: "Upload",
      skip: "Skip",
      save: "Save",
      back: "Back",
      cancel: "Cancel",
      remove: "Remove",
      signIn: "Sign in",
      noOptions: "No options",
      addToCaseHistory: "Add to case history",
      addNewTask: "Add new task",
      addNewMember: "Add new member",
      addNewEvent: "Add new event",
      dragAndDropOrBrowse: "Drag and drop or browse to upload",
      sortBy: "Sort by",
      filterBy: "Filter by",
      publish: "Publish",
      block: "Block",
      unblock: "Unblock",
      invite: "Invite",
      strength: {
        weak: "Weak",
        medium: "Medium",
        strong: "Strong",
      },
      loading: "Loading",
      delete: "Delete",
      edit: "Edit",
      reactivate: "Reactivate",
      complete: "Complete",
    },
    snackbar: {
      tenantCreated: "Tenant created successfully",
      caseCreated: "Case created successfully",
      userCreated: "User created successfully",
      jobTitleCreated: "Job title created successfully",
      taskCreated: "Task created successfully",
      teamMembersAdded: "Team members added successfully",
      teamMemberDeleted: "Team member deleted successfully",
      taskCompleted: "Task completed successfully",
      eventCreated: "Event created successfully",
      taskUpdated:"Task updated successfully",
    },
    popups: {
      deleteUserWarning:
        "Upon deletion, the team member will not be able to access the case and continue working on it, confirm?",
      suspendUserWarning:"When the account is suspended, the user will not be able to access the system at all.{linebreak} You can reactivate the account after stopping it when needed, confirm?",
     
      confirmUserDeletion: "Confirm user deletion",
      confirmTaskCompletion: "Confirm task completion",
      completeTaskWarning:
        "You are about to mark this task as complete, confirm?",
      confirmUserSuspend:"Confirm suspend this user",
    },
    numbering: ["First", "Second", "Third", "Fourth", "Fifth"],
    shared: {
      morning: "AM",
      evening: "PM",
      from: "From",
      to: "To",
    },
    client: {
      followIssuesOnApp:  "You can follow your issues on app",
      legitAppAvailable: "You can follow your issues on app",
    }
  },
};
