import * as types from "./types";

const INITIAL_STATE = {
  isSocketConnected: false,
};

export default function socket(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.IS_SOCKET_CONNECTED:
      return {
        ...state,
        isSocketConnected: action.payload,
      };
    default:
      return state;
  }
}
