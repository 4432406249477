import * as types from "./types";

export const caseClassificationsRequest = (payload) => ({
  type: types.CASE_CLASSIFICATIONS_REQUEST,
  payload,
});

export const caseClassificationsRecieve = (payload) => ({
  type: types.CASE_CLASSIFICATIONS_RECIEVE,
  payload,
});

export const caseTypesRequest = (payload) => ({
  type: types.CASE_TYPES_REQUEST,
  payload,
});

export const statusRecieve = (payload) => ({
  type: types.STATUS_RECIEVE,
  payload,
});
export const statusRequest = (payload) => ({
  type: types.STATUS_REQUEST,
  payload,
});
export const caseStatusRequest = (payload) => ({
  type: types.CASE_STATUS_REQUEST,
  payload,
});

export const caseStatusRecieve = (payload) => ({
  type: types.CASE_STATUS_RECIEVE,
  payload,
});

export const caseTypesRecieve = (payload) => ({
  type: types.CASE_TYPES_RECIEVE,
  payload,
});

export const countriesRequest = (payload) => ({
  type: types.COUNTRIES_REQUEST,
  payload,
});

export const countriesRecieve = (payload) => ({
  type: types.COUNTRIES_RECIEVE,
  payload,
});

export const citiesRequest = (payload) => ({
  type: types.CITIES_REQUEST,
  payload,
});

export const citiesRecieve = (payload) => ({
  type: types.CITIES_RECIEVE,
  payload,
});

export const countriesUnauthenticatedRequest = (payload) => ({
  type: types.COUNTRIES_UNAUTHENTICATED_REQUEST,
  payload,
});

export const countriesUnauthenticatedRecieve = (payload) => ({
  type: types.COUNTRIES_UNAUTHENTICATED_RECIEVE,
  payload,
});

export const citiesForSingleAddRequest = (payload) => ({
  type: types.CITIES_FOR_SINGLE_ADD_REQUEST,
  payload,
});
export const citiesForSingleAddRecieve = (payload) => ({
  type: types.CITIES_FOR_SINGLE_ADD_RECIEVE,
  payload,
});

export const courtsRequest = (payload) => ({
  type: types.COURTS_REQUEST,
  payload,
});

export const courtsRecieve = (payload) => ({
  type: types.COURTS_RECIEVE,
  payload,
});

export const circlesRequest = (payload) => ({
  type: types.CIRCLES_REQUEST,
  payload,
});

export const circlesRecieve = (payload) => ({
  type: types.CIRCLES_RECIEVE,
  payload,
});

export const courtDegreesRequest = (payload) => ({
  type: types.COURT_DEGREES_REQUEST,
  payload,
});

export const courtDegreesRecieve = (payload) => ({
  type: types.COURT_DEGREES_RECIEVE,
  payload,
});

export const firmNamesRequest = (payload) => ({
  type: types.FIRM_NAMES_REQUEST,
  payload,
});

export const firmNamesRecieve = (payload) => ({
  type: types.FIRM_NAMES_RECIEVE,
  payload,
});

export const jobTitlesRequest = (payload) => ({
  type: types.JOB_TITLES_REQUEST,
  payload,
});

export const jobTitlesRecieve = (payload) => ({
  type: types.JOB_TITLES_RECIEVE,
  payload,
});

export const createJobTitle = (payload) => ({
  type: types.CREATE_JOB_TITLE,
  payload,
});

export const allUsersRequest = (payload) => ({
  type: types.ALL_USERS_REQUEST,
  payload,
});

export const allUsersRecieve = (payload) => ({
  type: types.ALL_USERS_RECIEVE,
  payload,
});
export const allClientsRequest = (payload) => ({
  type: types.ALL_CLIENTS_REQUEST,
  payload,
});
export const allClientsRecieve = (payload) => ({
  type: types.ALL_CLIENTS_RECIEVE,
  payload,
});
export const allCaseMembersRequest = (payload) => ({
  type: types.ALL_CASE_MEMBERS_REQUEST,
  payload,
});
export const allCaseMembersRecieve = (payload) => ({
  type: types.ALL_CASE_MEMBERS_RECIEVE,
  payload,
});
export const getMembersOfSpecificCasesRequest = (payload) => ({
  type: types.GET_MEMBERS_OF_SPECIFIC_CASES_REQUEST,
  payload,
});
export const getMembersOfSpecificCasesRecieve = (payload) => ({
  type: types.GET_MEMBERS_OF_SPECIFIC_CASES_RECIEVE,
  payload,
});
export const getMembersOfAllCasesRequest = (payload) => ({
  type: types.GET_MEMBERS_OF_ALL_CASES_REQUEST,
  payload,
});
export const getMembersOfAllCasesRecieve = (payload) => ({
  type: types.GET_MEMBERS_OF_ALL_CASES_RECIEVE,
  payload,
});
export const allCaseManagerRequest = (payload) => ({
  type: types.ALL_CASE_MANAGER_REQUEST,
  payload,
});
export const allCaseManagerRecieve = (payload) => ({
  type: types.ALL_CASE_MANAGER_RECIEVE,
  payload,
});

export const taskCategoriesRequest = (payload) => ({
  type: types.TASK_CATEGORIES_REQUEST,
  payload,
});

export const taskCategoriesRecieve = (payload) => ({
  type: types.TASK_CATEGORIES_RECIEVE,
  payload,
});

export const eventTypesRequest = (payload) => ({
  type: types.EVENT_TYPES_REQUEST,
  payload,
});

export const eventTypesRecieve = (payload) => ({
  type: types.EVENT_TYPES_RECIEVE,
  payload,
});

export const relationsRequest = (payload) => ({
  type: types.RELATIONS_REQUEST,
  payload,
});

export const relationsRecieve = (payload) => ({
  type: types.RELATIONS_RECIEVE,
  payload,
});
export const createRelationTitle = (payload) => ({
  type: types.CREATE_RELATION_TITLE,
  payload,
});
export const decisionsTypesRequest = (payload) => ({
  type: types.DECISION_TYPES_REQUEST,
  payload,
});

export const decisionsTypesRecieve = (payload) => ({
  type: types.DECISION_TYPES_RECIEVE,
  payload,
});

export const timezoneRequest = (payload) => ({
  type: types.TIMEZONES_REQUEST,
  payload,
});

export const timezoneRecieve = (payload) => ({
  type: types.TIMEZONES_RECIEVE,
  payload,
});

//case classification
export const caseMainClassificationRequest = (payload) => ({
  type: types.CASE_MAIN_CLASSIFICATION_REQUEST,
  payload,
});

export const caseMainClassificationRecieve = (payload) => ({
  type: types.CASE_MAIN_CLASSIFICATION_RECIEVE,
  payload,
});

export const caseSubClassificationRequest = (payload) => ({
  type: types.CASE_SUB_CLASSIFICATION_REQUEST,
  payload,
});

export const caseSubClassificationRecieve = (payload) => ({
  type: types.CASE_SUB_CLASSIFICATION_RECIEVE,
  payload,
});

export const caseClassificationRequest = (payload) => ({
  type: types.CASE_CLASSIFICATION_REQUEST,
  payload,
});

export const caseClassificationRecieve = (payload) => ({
  type: types.CASE_CLASSIFICATION_RECIEVE,
  payload,
});
export const mainClassClassificationRecieve = (payload) => ({
  type: types.MAIN_CLASS_CLASSIFICATION_RECIEVE,
  payload,
});
export const allSubClassesClassificationRecieve = (payload) => ({
  type: types.ALL_SUB_CLASSES_CLASSIFICATION_RECIEVE,
  payload,
});
export const allClassesOfSubClassesClassificationRecieve = (payload) => ({
  type: types.ALL_CLASSES_OF_SUB_CLASSES_CLASSIFICATION_RECIEVE,
  payload,
});
export const legalLibraryRequest = (payload) => ({
  type: types.LEGAL_LIBRARY_REQUEST,
  payload,
});

export const legalLibraryRecieve = (payload) => ({
  type: types.LEGAL_LIBRARY_RECIEVE,
  payload,
});

export const allContactsRequest = (payload) => ({
  type: types.ALL_CONTACTS_REQUEST,
  payload,
});

export const allContactsRecieve = (payload) => ({
  type: types.ALL_CONTACTS_RECIEVE,
  payload,
});

export const allPartiesRequest = (payload) => ({
  type: types.ALL_PARTIES_REQUEST,
  payload,
});

export const allPartiesRecieve = (payload) => ({
  type: types.ALL_PARTIES_RECIEVE,
  payload,
});

export const PartyTypeRequest = (payload) => ({
  type: types.PARTY_TYPE_REQUEST,
  payload,
});

export const PartyTypeRecieve = (payload) => ({
  type: types.PARTY_TYPE_RECIEVE,
  payload,
});

export const PartyCharacterRequest = (payload) => ({
  type: types.PARTY_CHARACTER_REQUEST,
  payload,
});

export const PartyCharacterRecieve = (payload) => ({
  type: types.PARTY_CHARACTER_RECIEVE,
  payload,
});

export const identificationRequest = (payload) => ({
  type: types.IDENTIFICATION_REQUEST,
  payload,
});

export const identificationRecieve = (payload) => ({
  type: types.IDENTIFICATION_RECIEVE,
  payload,
});
export const identificationUnauthenticatedRequest = (payload) => ({
  type: types.IDENTIFICATION_UNAUTHENTICATED_REQUEST,
  payload,
});

export const identificationUnauthenticatedRecieve = (payload) => ({
  type: types.IDENTIFICATION_UNAUTHENTICATED_RECIEVE,
  payload,
});

export const nationalityRequest = (payload) => ({
  type: types.NATIONALITY_REQUEST,
  payload,
});

export const nationalityRecieve = (payload) => ({
  type: types.NATIONALITY_RECIEVE,
  payload,
});

export const companyTypeRequest = (payload) => ({
  type: types.COMPANY_TYPE_REQUEST,
  payload,
});

export const companyTypeRecieve = (payload) => ({
  type: types.COMPANY_TYPE_RECIEVE,
  payload,
});

export const sessionTypeRequest = (payload) => ({
  type: types.SESSION_TYPE_REQUEST,
  payload,
});

export const sessionTypeRecieve = (payload) => ({
  type: types.SESSION_TYPE_RECIEVE,
  payload,
});

export const sessionOutcomeTypeRequest = (payload) => ({
  type: types.SESSION_OUTCOME_TYPE_REQUEST,
  payload,
});

export const sessionOutcomeTypeRecieve = (payload) => ({
  type: types.SESSION_OUTCOME_TYPE_RECIEVE,
  payload,
});

export const displayViewInSingleTab = (payload) => ({
  type: types.DISPLAY_VIEW_IN_SING_TAB,
  payload,
});

export const displayViewInLandingPage = (payload) => ({
  type: types.DISPLAY_VIEW_IN_LANDING_PAGE,
  payload,
});

export const printTableData = (payload) => ({
  type: types.PRINT_TABLE_DATA,
  payload,
});

export const listFilterValues = (payload) => ({
  type: types.LIST_FILTER_VALUES,
  payload,
});

export const sessionExpirationPopupIsOpen = (payload) => ({
  type: types.SESSION_EXPIRASSION_POPUP_IS_OPEN,
  payload,
});

export const exportExcelTemplateRequest = (payload) => ({
  type: types.EXPORT_EXCEL_TEMPLATE_REQUEST,
  payload,
});

export const importExcelTemplateRequest = (payload) => ({
  type: types.IMPORT_EXCEL_TEMPLATE_REQUEST,
  payload,
});
export const importExcelTemplateResponse = (payload) => ({
  type: types.IMPORT_EXCEL_TEMPLATE_RESPONSE,
  payload,
});

export const nabourhoodRequest = (payload) => ({
  type: types.NABOURHOOD_REQUEST,
  payload,
});
export const nabourhoodResponse = (payload) => ({
  type: types.NABOURHOOD_RESPONSE,
  payload,
});