import * as types from "./types";

const INITIAL_STATE = {
  role: {},
};

export default function UserRoles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_USER_ROLE_IN_THIS_CASE:
      return {
        ...state,
        role: action.payload,
      };
    default:
      return state;
  }
}
