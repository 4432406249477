// list
export const GET_TASKS_LIST = "GET_TASKS_LIST";
export const ADD_TASKS_LIST = "ADD_TASKS_LIST";
// task actions
export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const COMPLETE_TASK_REQUEST = "COMPLETE_TASK_REQUEST";
//get single tasks and update
export const GET_TASK = "GET_TASK";
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";

export const ADD_NEW_TASK_CATEGORY_REQUEST = "ADD_NEW_TASK_CATEGORY_REQUEST";
export const ADD_NEW_TASK_CATEGORY_RECIEVE = "ADD_NEW_TASK_CATEGORY_RECIEVE";

export const SORT_AND_FILTER_REQUEST = "SORT_AND_FILTER_REQUEST";
export const SORT_AND_FILTER_RECEIVE = "SORT_AND_FILTER_RECEIVE";

export const ADD_SORT_AND_FILTER_KEYS = "ADD_SORT_AND_FILTER_KEYS";
