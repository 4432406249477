export const CASE_ATTACHMENT_REQUEST = "CASE_ATTACHMENT_REQUEST";
export const CASE_ATTACHMENT_RECEIVE = "CASE_ATTACHMENT_RECEIVE";

export const FOLDER_ATTACHMENT_REQUEST = "FOLDER_ATTACHMENT_REQUEST";
export const FOLDER_ATTACHMENT_RECEIVE = "FOLDER_ATTACHMENT_RECEIVE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";

export const CREATE_FOLDER_REQUEST = "CREATE_FOLDER_REQUEST";
export const DELETE_FOLDER_OR_FILE = "DELETE_FOLDER_OR_FILE";

export const MOVE_FILE = "MOVE_FILE";
export const RENAME_FILE_OR_FOLDER = "RENAME_FILE_OR_FOLDER";
 