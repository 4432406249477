import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
// import { includedInPathName } from "../../utils/Shared";
import WhiteLogo from "../../assets/images/main/mustandWhiteLog.svg";
import UpArrow from "../../assets/images/landingPage/upArrow.svg";
import { Logo2030 } from "../../utils/Images";
import History from "../../routes/History";

const Footer = ({ intl: { messages } }) => {
  const [dynamicImports, setDynamicImports] = useState(null);

  useEffect(() => {
    // Dynamically import the shared dynamicImports  
    const loadDynamicImports = async () => {
      const { includedInPathName } = await import('../../utils/Shared.js');
      setDynamicImports({ includedInPathName });
    };

    loadDynamicImports();
  }, []);
  return (
    <footer id="footer">
      <div class="container">
        <div class="row py-3 pt-4 align-items-center">
          <div className="height-70 d-flex col-md-4 my-auto align-items-end">
            <img
              src={Logo2030}
              alt="Logo2030"
              className="border-left-gray padd-l"
              height="auto"
              loading="lazy"
            />
            <div className="px-3">
              <img loading="lazy" src={WhiteLogo} alt="WhiteLogo"height={'60'}/>
              <p className="f_size_10 m-0">
                {messages.landingPage.footer.title}
              </p>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <a href={dynamicImports?.includedInPathName('contracts', "/contracts/سياسة-الخصوصية", "/سياسة-الخصوصية")} target="_blank">
              <p className="mb-0 f_size_14">
                {messages.landingPage.footer.privacyPolicy}
              </p>
            </a>
            <p className="mb-0 mx-3">.</p>
            <a href={dynamicImports?.includedInPathName('contracts', "/contracts/الشروط-والأحكام", "/الشروط-والأحكام")} target="_blank">
              <p
                className="mb-0 f_size_14 cursor-pointer">
                {messages.landingPage.footer.termsAndConditions}
              </p>
            </a>
          </div>
          <div className="col-md-4 justify-content-end align-items-center my-auto d-flex">
            <p className="px-4 mb-0 f_size_14 allRightsReservedToLegit">
              {messages.landingPage.footer.allRightsReservedToLegit}
            </p>
            <div
              className="upperArrow cursor-pointer"
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
            >
              <img src={UpArrow} alt="UpArrow" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default injectIntl(Footer);
