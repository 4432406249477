import * as types from "./types";

export const addBreadcrumb = (payload) => {
  return { type: types.ADD_BREADCRUMB, payload };
};

export const addNavbarBreadcrumb = (payload) => {
  return { type: types.ADD_NAVBAR_BREADCRUMB, payload };
};

export const addCustomBreadcrumb = (payload) => {
  return { type: types.ADD_CUSTOM_BREADCRUMB, payload };
};