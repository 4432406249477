export const GET_STATISTICS = "GET_STATISTICS";
export const ADD_STATISTICS= "ADD_STATISTICS";
export const GET_YEARS= "GET_YEARS";
export const ADD_YEARS= "ADD_YEARS";
export const CURRENT_VIEW_IN_DASHBOARD = "CURRENT_VIEW_IN_DASHBOARD"

export const GET_CALENDER_LIST_REQUEST= "GET_CALENDER_LIST_REQUEST";
export const GET_CALENDER_LIST= "GET_CALENDER_LIST";

export const CREATE_APPOINTMENT_REQUEST= "CREATE_APPOINTMENT_REQUEST";
export const CREATE_APPOINTMENT= "CREATE_APPOINTMENT";

export const VIEW_APPOINTMENT_REQUEST= "VIEW_APPOINTMENT_REQUEST";
export const VIEW_APPOINTMENT= "VIEW_APPOINTMENT";

export const EDIT_APPOINTMENT_REQUEST= "EDIT_APPOINTMENT_REQUEST";
export const EDIT_APPOINTMENT= "EDIT_APPOINTMENT";

export const DELETE_APPOINTMENT_REQUEST= "DELETE_APPOINTMENT_REQUEST";
export const DELETE_APPOINTMENT= "DELETE_APPOINTMENT";

export const CALENDAR_FILTER_VALUES = "CALENDAR_FILTER_VALUES"

export const GET_RECENT_ADDED_TASKS_REQUEST= "GET_RECENT_ADDED_TASKS_REQUEST";
export const GET_RECENT_ADDED_TASKS= "GET_RECENT_ADDED_TASKS";

export const SORT_AND_FILTER_TASKS_REQUEST= "SORT_AND_FILTER_TASKS_REQUEST";
export const SORT_AND_FILTER_TASKS= "SORT_AND_FILTER_TASKS";

export const GET_DUE_INVOICES_REQUEST= "GET_DUE_INVOICES_REQUEST";
export const GET_DUE_INVOICES= "GET_DUE_INVOICES";

export const GET_RECENT_COMMENTS_REQUEST = "GET_RECENT_COMMENTS_REQUEST";
export const GET_RECENT_COMMENTS = "GET_RECENT_COMMENTS";

export const GET_RECENT_OPENED_REQUEST = "GET_RECENT_OPENED_REQUEST";
export const GET_RECENT_OPENED = "GET_RECENT_OPENED";

export const GET_MAIN_MATRICS_REQUEST = 'GET_MAIN_MATRICS_REQUEST';
export const GET_MAIN_MATRICS = 'GET_MAIN_MATRICS';

export const GET_CASE_CLASS_MATRICS_REQUEST = 'GET_CASE_CLASS_MATRICS_REQUEST';
export const GET_CASE_CLASS_MATRICS = 'GET_CASE_CLASS_MATRICS';

export const GET_VERDICT_MATRICS_REQUEST = 'GET_VERDICT_MATRICS_REQUEST';
export const GET_VERDICT_MATRICS = 'GET_VERDICT_MATRICS';

export const GET_SESSION_MATRICS_REQUEST = 'GET_SESSION_MATRICS_REQUEST';
export const GET_SESSION_MATRICS = 'GET_SESSION_MATRICS';

export const GET_CLIENT_MATRICS_REQUEST = 'GET_CLIENT_MATRICS_REQUEST';
export const GET_CLIENT_MATRICS = 'GET_CLIENT_MATRICS';

export const GET_TASKS_MATRICS_REQUEST = 'GET_TASKS_MATRICS_REQUEST';
export const GET_TASKS_MATRICS = 'GET_TASKS_MATRICS';
