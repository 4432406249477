import * as types from "./types";

const INITIAL_STATE = {
  content: {},
  landingPageCountries: [],
};

export default function landingPage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LANDING_PAGE_RECEIVE:
      return { ...state, content: action.payload };
    case types.COUNTRIES_LANDING_PAGE_RECEIVE:
      return { ...state, landingPageCountries: action.payload };
    default:
      return state;
  }
}
