import { useEffect, useState } from 'react';
import { Helmet } from '../../custom_modules/react-helmet-async';
import { metaTagsContent } from "../../utils/Constants";
import mustandLogo from "../../assets/images/main/mustandLogo-vertical.png"

const SEO = ({ id }) => {

    const [targetPage, setTargetPage] = useState({});
    useEffect(() => {
        if (id) {
            setTargetPage(metaTagsContent?.find(ele => ele.id === id))
        }
    }, [id])
    return !window.location.pathname?.includes('-app') ?
        <Helmet>
            { /* Standard metadata tags */}
            <title>{targetPage?.subTitle}</title>
            <meta name='pagename' content={targetPage?.title} />
            <meta name='description' content={targetPage?.description} />
            {/* dev */}
            <link rel="canonical" href={`https://mustand.co${targetPage?.url}`} />

            { /* Facebook and Linkedin tags */}
            <meta property="og:url" content={targetPage?.url} />
            <meta property="og:site_name" content={targetPage?.site_name || 'مستند'} />
            <meta property="og:title" content={targetPage?.specific_title || targetPage?.title} />
            <meta property="og:description" content={targetPage?.specific_description || targetPage?.description} />
            <meta name='og:image' content={mustandLogo}></meta>

            { /* Twitter tags */}
            {targetPage?.creator && <meta name="twitter:creator" content={targetPage?.creator} />}
            {targetPage?.card && <meta name="twitter:card" content={targetPage?.card} />}
            <meta name="twitter:title" content={targetPage?.specific_title || targetPage?.title} />
            <meta name="twitter:description" content={targetPage?.specific_description || targetPage?.description} />
        </Helmet> : ''
}
export default SEO;