import React from "react";
import { Route } from "react-router-dom";
import History from "../History";
import Layout from "../../components/Layout/MainLayout";
import Auth from "../../utils/Auth";
import AuthLayout from "../../components/Layout/AuthLayout";
import { getSubDomain, removePersonalDataFromURL } from "../../utils/Shared";
import { roles } from "../../utils/Constants";
import store from "../../store";

const {
  auth: { user },
} = store.getState();
export const WithLayout = (component, layout) => {
  return layout === "auth" ? (
    <AuthLayout>{component}</AuthLayout>
  ) : (
    <Layout>{component}</Layout>
  );
};

const FirmUsersRoute = ({ component: Component, path, layout, ...rest }) => {
  const lang = localStorage.getItem("lang");
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  if (History.location.search) {
    removePersonalDataFromURL();
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (Auth.isAuth() && getSubDomain()) {
          if (userData || user) {
            if (
              (path == "/" || path == "/cases") &&
              (userData?.role == roles.FINANCE_SPECIALIST ||
                user?.role == roles.FINANCE_SPECIALIST)
            ) {
              History.push("/invoices");
            } else {
              return WithLayout(<Component {...props} />, layout);
            }
          }
        } else {
          History.push("/");
          return null;
        }
      }}
    />
  );
};

export default FirmUsersRoute;
