import * as types from "./types";

const INITIAL_STATE = {
  firm: {},
  generalFirmSettings: {},
};

export default function FirmSettings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_FIRM_SETTINGS:
      return {
        ...state,
        firm: action.payload.date_type ? state.firm : action.payload,
        generalFirmSettings: action.payload.date_type
          ? action.payload
          : state.generalFirmSettings,
      };
    default:
      return state;
  }
}
