import * as types from "./types";

export const landingPageRequest = (payload) => ({
  type: types.LANDING_PAGE_REQUEST,
  payload,
});

export const landingPageReceive = (payload) => ({
  type: types.LANDING_PAGE_RECEIVE,
  payload,
});

export const contactUsRequest = (payload) => ({
  type: types.CONTACT_US_REQUEST,
  payload,
});

export const countriesRequest = (payload) => ({
  type: types.COUNTRIES_LANDING_PAGE_REQUEST,
  payload,
});

export const countriesReceive = (payload) => ({
  type: types.COUNTRIES_LANDING_PAGE_RECEIVE,
  payload,
});
