// list
export const GET_VERDICTS_LIST = "GET_VERDICTS_LIST";
export const ADD_VERDICTS_LIST = "ADD_VERDICTS_LIST";
//Create
export const CREATE_VERDICT_REQUEST = "CREATE_VERDICT_REQUEST";
//get single item
export const GET_VERDICT_REQUEST = "GET_VERDICT_REQUEST";
export const GET_VERDICT_RECEIVE = "GET_VERDICT_RECEIVE";
//update
export const UPDATE_VERDICT_REQUEST = "UPDATE_VERDICT_REQUEST";
//delete
export const DELETE_VERDICT_REQUEST = "DELETE_VERDICT_REQUEST";
//Verdict Characteristic
export const GET_VERDICTS_CHARACTERISTICS = "GET_VERDICTS_CHARACTERISTICS";
export const ADD_VERDICTS_CHARACTERISTICS = "ADD_VERDICTS_CHARACTERISTICS";
