import {lazy} from 'react';
import store from "../store";
import { disableLoader, loader } from "../store/Loader/actions";
import Auth from "../utils/Auth";
import { dispatchSnackbarError } from "../utils/Shared";
import { hideSnackbarAction } from "../store/Snackbar/actions";
import History from "../routes/History";
import { sessionExpirationPopupIsOpen } from "../store/Lookups/actions";

const messages = lazy(() => import("../assets/locals/messages"));

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : userInfo && userInfo.token
    ? userInfo.token
    : null;

  if (isHandlerEnabled(request)) {
    const { disableLoader } = store.getState().loader;
    // Modify request here
    if (!disableLoader) store.dispatch(loader(true));

    if (typeof Storage !== "undefined") {
      request.headers["Lang"] = localStorage.getItem("lang");
    }

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return request;
};

export const successHandler = (response) => {
  if(response.data?.isTokenExpired){
    localStorage.setItem("session_expiration", true);
        store.dispatch(
          sessionExpirationPopupIsOpen({ default: true, login: false })
        );
  }
  if (isHandlerEnabled(response)) {
    // Hanlde Response
    store.dispatch(loader(false));
    store.dispatch(disableLoader(false));
  }
  return response;
};

export const errorHandler = (error) => {
  const lang = store.getState().lang;
  if (isHandlerEnabled(error.config)) {
    store.dispatch(loader(false));
    store.dispatch(disableLoader(false));
    //session ends //free trial firm deleted
    if (error.response?.status === 401 &&  error.response?.data?.message === "Unauthenticated.") {
      dispatchSnackbarError(messages[lang].errors.youCantRichThisPage);
      Auth.isAuth();
      Auth.signOut();
      localStorage.clear()
    }
    //[403] try to access with no permission or removed from it
    //[404] navigate in case and removed from it in same time]
    else if (error.response?.status === 424) {
      //expire
      dispatchSnackbarError(messages[lang].errors.accessDenied);
      History.push("/cases");
    } else if (navigator && navigator.onLine === false) {
      dispatchSnackbarError(messages[lang].errors.networkError);
    } else {
      return Promise.reject({ ...error });
    }
  } else {
    if (error.response?.status !== 401 || error.response?.status !== 400) {
      dispatchSnackbarError(messages[lang].errors.errorOccurred);
    }
  }
};
