// Replace with your request types
export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";

export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export const ADD_EVENTS_LIST = "ADD_EVENTS_LIST";

export const ADD_EVENTS_SEARCH_VALUE = "ADD_EVENTS_SEARCH_VALUE";
export const ADD_EVENTS_FILTER_BY = "ADD_EVENTS_FILTER_BY";
//get single evnmt and update
export const GET_EVENT = "GET_EVENT";
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
