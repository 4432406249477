import * as types from "./types";
import { getSubDomain } from "../../utils/Shared";
import { HIJRI } from "../../utils/Constants";
const INITIAL_STATE = {
  user:
    typeof Storage !== "undefined" && localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  isTenant: getSubDomain() ? true : false,
  tenantName: "",
  routes: [],
  validEmail: "",
  expired: false,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOGIN_RECEIVE:
      return {
        ...state,
        user: action.payload,
      };
    case types.IS_FREE_TRIAL_EXPIRED:
      return {
        ...state,
        expired: action.payload,
      };
    case types.CHECK_IF_TENANT_VERIFIED_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.IS_TENANT:
      return {
        ...state,
        isTenant: action.payload,
      };
    case types.SET_TENANT_NAME:
      return {
        ...state,
        tenantName: action.payload,
      };
    case types.GENERATE_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case types.CHECK_EMAIL_VALIDATION_RECIEVE:
      return {
        ...state,
        validEmail: action.payload,
      };
    case types.CHECK_EMAIL_VERIFICATION_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.DEVICE_TYPE:
      return {
        ...state,
        deviceType: action.payload,
      };
    default:
      return state;
  }
}
