import * as types from "./types";

const INITIAL_STATE = {
  caseClassifications: [],
  caseTypes: [],
  caseStatus: [],
  countries: [],
  cities: [],
  citiesUnauthenticated: [],
  singleCities: [],
  circles: [],
  courts: [],
  courtDegrees: [],
  firmNames: [],
  jobTitles: [],
  allUsers: {},
  allClients: {},
  allCaseMembers: {},
  membersOfSpecificCases: {},
  membersOfAllCases: {},
  taskCategories: [],
  eventTypes: [],
  relations: [],
  decisionsTypes: [],
  timezones: [],
  legalLibrary: [],
  caseMainClassification: [],
  caseSubClassification: [],
  caseClassification: [],
  mainClassClassification: [],
  allSubClassesClassification: [],
  allClassesOfSubClassesClassification: [],
  contacts: [],
  parties: [],
  partyTypes: [],
  partyCharacters: [],
  identifications: [],
  identificationsUnauthenticated: [],
  nationalities: [],
  // nationalitiesUnauthenticated: [],
  companyType: [],
  sessionType: [],
  sessionOutcomeType: [],
  currentViewInSingleTab: {},
  currentViewInLandingPage: 'landingPage',
  printTableData:{},
  listFilterValues: {},
  sessionExpirationPopupIsOpen: {default: false, login: false},
  importExcelTemplateResponse: {count: null, failImportMsg: '', subSheet: ''},
  nabourhood: [],
};

export default function lookups(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CASE_CLASSIFICATIONS_RECIEVE:
      return { ...state, caseClassifications: action.payload };
    case types.CASE_TYPES_RECIEVE:
      return { ...state, caseTypes: action.payload };
    case types.CASE_STATUS_RECIEVE:
      return { ...state, caseStatus: action.payload };
    case types.COUNTRIES_RECIEVE:
      return { ...state, countries: action.payload };
    case types.CITIES_RECIEVE:
      return { ...state, cities: action.payload };
    case types.CITIES_FOR_SINGLE_ADD_RECIEVE:
      return { ...state, singleCities: action.payload };
    case types.CIRCLES_RECIEVE:
      return { ...state, circles: action.payload };
    case types.COURTS_RECIEVE:
      return { ...state, courts: action.payload };
    case types.STATUS_RECIEVE:
      return { ...state, status: action.payload };
    case types.COURT_DEGREES_RECIEVE:
      return { ...state, courtDegrees: action.payload };
    case types.FIRM_NAMES_RECIEVE:
      return { ...state, firmNames: action.payload };
    case types.JOB_TITLES_RECIEVE:
      return { ...state, jobTitles: action.payload };
    case types.ALL_USERS_RECIEVE:
      return {
        ...state,
        allUsers: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.allUsers.result || []),
                  ...(action.payload.result || []),
                ],
              }
            : {}),
        },
      };
    case types.ALL_CLIENTS_RECIEVE:
      return {
        ...state,
        allClients: {
          ...action?.payload,
          ...(action?.payload?.pagination &&
          action?.payload?.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.allClients.result || []),
                  ...(action.payload.result || []),
                ],
              }
            : {}),
        },
      };
    case types.ALL_CASE_MEMBERS_RECIEVE:
      return {
        ...state,
        allCaseMembers: {
          ...action.payload,
          ...(action.payload.pagination &&
          action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.allCaseMembers.result || []),
                  ...(action.payload.result || []),
                ],
              }
            : {}),
        },
      };
      case types.GET_MEMBERS_OF_SPECIFIC_CASES_RECIEVE:
        return {
          ...state,
          membersOfSpecificCases: {
            ...action.payload,
            ...(action.payload.pagination &&
            action.payload.pagination?.current_page !== 1
              ? {
                  result: [
                    ...(state.membersOfSpecificCases.result || []),
                    ...(action.payload.result || []),
                  ],
                }
              : {}),
          },
        };
        case types.GET_MEMBERS_OF_ALL_CASES_RECIEVE:
          return {
            ...state,
            membersOfAllCases: {
              ...action.payload,
              ...(action.payload.pagination &&
              action.payload.pagination?.current_page !== 1
                ? {
                    result: [
                      ...(state.membersOfAllCases.result || []),
                      ...(action.payload.result || []),
                    ],
                  }
                : {}),
            },
          };
    case types.TASK_CATEGORIES_RECIEVE:
      return { ...state, taskCategories: action.payload };
    case types.EVENT_TYPES_RECIEVE:
      return { ...state, eventTypes: action.payload };
    case types.RELATIONS_RECIEVE:
      return { ...state, relations: action.payload };
    case types.DECISION_TYPES_RECIEVE:
      return { ...state, decisionsTypes: action.payload };
    case types.TIMEZONES_RECIEVE:
      return {
        ...state,
        timezones: action.payload,
      };
    case types.LEGAL_LIBRARY_RECIEVE:
      return {
        ...state,
        legalLibrary: action.payload,
      };
    case types.CASE_MAIN_CLASSIFICATION_RECIEVE:
      return {
        ...state,
        caseMainClassification: action.payload,
      };
    case types.CASE_SUB_CLASSIFICATION_RECIEVE:
      return {
        ...state,
        caseSubClassification: action.payload,
      };
    case types.CASE_CLASSIFICATION_RECIEVE:
      return {
        ...state,
        caseClassification: action.payload,
      };
    case types.MAIN_CLASS_CLASSIFICATION_RECIEVE:
      return {
        ...state,
        mainClassClassification: action.payload,
      };
    case types.ALL_SUB_CLASSES_CLASSIFICATION_RECIEVE:
      return {
        ...state,
        allSubClassesClassification: action.payload,
      };
    case types.ALL_CLASSES_OF_SUB_CLASSES_CLASSIFICATION_RECIEVE:
      return {
        ...state,
        allClassesOfSubClassesClassification: action.payload,
      };
    case types.ALL_CONTACTS_RECIEVE:
      return {
        ...state,
        contacts: {
          ...action.payload,
          ...(action.payload.pagination &&
          action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.contacts?.result || []),
                  ...(action.payload.result || []),
                ],
              }
            : {}),
        },
      };
    case types.ALL_PARTIES_RECIEVE:
      return {
        ...state,
        parties: {
          ...action.payload,
          ...(action.payload.pagination &&
          action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.parties?.result || []),
                  ...(action.payload.result || []),
                ],
              }
            : {}),
        },
      };
    case types.PARTY_TYPE_RECIEVE:
      return {
        ...state,
        partyTypes: action.payload,
      };
    case types.PARTY_CHARACTER_RECIEVE:
      return {
        ...state,
        partyCharacters: action.payload,
      };
    case types.IDENTIFICATION_RECIEVE:
      return {
        ...state,
        identifications: action.payload,
      };
      case types.IDENTIFICATION_UNAUTHENTICATED_RECIEVE:
        return {
          ...state,
          identificationsUnauthenticated: action.payload,
        };
    case types.NATIONALITY_RECIEVE:
      return {
        ...state,
        nationalities: action.payload,
      };
    case types.COMPANY_TYPE_RECIEVE:
      return {
        ...state,
        companyType: action.payload,
      };
    case types.SESSION_TYPE_RECIEVE:
      return {
        ...state,
        sessionType: action.payload,
      };
      case types.SESSION_OUTCOME_TYPE_RECIEVE:
        return {
          ...state,
          sessionOutcomeType: action.payload,
        };
    case types.DISPLAY_VIEW_IN_SING_TAB:
      return {
        ...state,
        currentViewInSingleTab: action.payload,
      };
      case types.DISPLAY_VIEW_IN_LANDING_PAGE:
        return {
          ...state,
          currentViewInLandingPage: action.payload,
        };
        case types.PRINT_TABLE_DATA:
          localStorage.setItem('printTableData', action.payload ? JSON.stringify(action.payload) : {})
          return {
            ...state,
            printTableData: action.payload,
          };
          case types.LIST_FILTER_VALUES:
            return {
              ...state,
              listFilterValues: action.payload,
            };
            case types.SESSION_EXPIRASSION_POPUP_IS_OPEN:
              return {
                ...state,
                sessionExpirationPopupIsOpen: action.payload,
              };
              case types.IMPORT_EXCEL_TEMPLATE_RESPONSE:
                return {
                  ...state,
                  importExcelTemplateResponse: action.payload,
                };
                case types.NABOURHOOD_RESPONSE:
                  return {
                    ...state,
                    nabourhood: action.payload,
                  };
    default:
      return state;
  }
}
