// list
export const GET_SESSIONS_LIST = "GET_SESSIONS_LIST";
export const ADD_SESSIONS_LIST = "ADD_SESSIONS_LIST";
//filter
export const SORT_AND_FILTER_SESSIONS_REQUEST =
  "SORT_AND_FILTER_SESSIONS_REQUEST";
export const SORT_AND_FILTER_SESSIONS_RECEIVE =
  "SORT_AND_FILTER_SESSIONS_RECEIVE";

export const ADD_SORT_AND_FILTER_SESSIONS_KEYS =
  "ADD_SORT_AND_FILTER_SESSIONS_KEYS";

export const CREATE_SESSION_REQUEST = "CREATE_SESSION_REQUEST";
export const CREATE_SESSION_RECEIVE = "CREATE_SESSION_RECEIVE";

export const GET_SESSION_REQUEST = "GET_SESSION_REQUEST";
export const GET_SESSION_RECEIVE = "GET_SESSION_RECEIVE";

export const UPDATE_SESSION_REQUEST = "UPDATE_SESSION_REQUEST";
export const UPDATE_SESSION_RECEIVE = "UPDATE_SESSION_RECEIVE";

export const DELETE_SESSION_REQUEST = "DELETE_SESSION_REQUEST";
export const DELETE_SESSION_RECEIVE = "DELETE_SESSION_RECEIVE";

export const ADD_SESSION_OUTCOME_REQUEST = "ADD_SESSION_OUTCOME_REQUEST";
export const ADD_SESSION_OUTCOME_RECEIVE = "ADD_SESSION_OUTCOME_RECEIVE";

export const ADD_SESSION_REPORT_REQUEST = "ADD_SESSION_REPORT_REQUEST";
export const ADD_SESSION_REPORT_RECEIVE = "ADD_SESSION_REPORT_RECEIVE";

export const UPDATE_SESSION_REPORT_REQUEST = "UPDATE_SESSION_REPORT_REQUEST";
export const UPDATE_SESSION_REPORT_RECEIVE = "UPDATE_SESSION_REPORT_RECEIVE";

export const APPROVE_REPORT_REQUEST = "APPROVE_REPORT_REQUEST";

export const UPDATE_SESSION_OUTCOME_REQUEST = "UPDATE_SESSION_OUTCOME_REQUEST";

export const GENERATE_SESSION_REPORT_REQUEST = "GENERATE_SESSION_REPORT_REQUEST";
export const GENERATE_SESSION_REPORT_RECEIVE = "GENERATE_SESSION_REPORT_RECEIVE";