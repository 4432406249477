import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) =>
      props.color ? theme.palette.text[props.color] || props.color : "#FFFFFF", //#fff
    borderRadius: "8px",
    border: (props) =>
      props.bgColorKey === "transparent"
        ? `1px solid #d0d0d0`
        : "",
    minWidth: (props) => (props.minWidth ? props.minWidth : "100px"),
    minHeight: (props) => (props.minHeight ? props.minHeight : "auto"),
    fontSize: "1rem",
    width: (props) => (props.fullWidth ? "100%" : "auto"),
    padding: (props) => (props.padding ? props.padding : "12px 24px"),
    boxShadow: (props) => (props.boxShadow ? props.boxShadow : "4px 4px 10px rgba(0, 0, 0, 0.08)"),
    backgroundColor: (props) =>
      props.bgColorKey
        ? theme.palette.background[props.bgColorKey]
        : theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: (props) =>
        props.bgColorKey
          ? theme.palette.background[props.bgColorKey]
          : theme.palette.secondary.main,
    },
  },
}));

const ButtonComponent = React.forwardRef(
  (
    {
      bgColorKey,
      color,
      padding,
      fullWidth,
      content,
      handleClick,
      classes,
      type,
      id,
      className,
      minWidth,
      minHeight,
      disabled,
      boxShadow,
      ...rest
    },
    ref
  ) => {
    const classNames = useStyles({
      bgColorKey,
      color,
      padding,
      fullWidth,
      minWidth,
      minHeight
    });
    return (
      <Button
        variant="contained"
        type={type}
        className={`${classes} ${classNames.root} ${className}`}
        onClick={handleClick}
        ref={ref}
        id={id}
        disabled={disabled || false}
        {...rest}
      >
        {content}
      </Button>
    );
  }
);

export default ButtonComponent;
