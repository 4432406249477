import { axiosInstance } from "../../network/api";

export const landingPageRequest = async (payload) => {
  return await axiosInstance.get(`/api/landing-page`);
};

export const contactUsRequest = async (payload) => {
  return await axiosInstance.post(`/api/contact-us`, payload);
};

export const countriesRequest = async (payload) => {
  return await axiosInstance.get(`/api/lookup/Country`, payload);
};
