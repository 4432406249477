import React,{ useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import ButtonComponent from "../Controls/Button";
import LeftArrowIcon from "../../assets/images/icons/general/leftArrow";
import { logo2030Gray, logo2030GrayWebp } from "../../utils/Images";
import ResponsiveImage from "../../components/ResponsiveImage";

const Header = ({ intl: { messages }, content, id }) => {

const [dynamicImports, setDynamicImports] = useState(null);

  useEffect(() => {
    // Dynamically import the shared functions  
    const loadDynamicImports = async () => {
      const { navigateToFreeTrail } = await import('../../utils/Shared.js');
       setDynamicImports({ navigateToFreeTrail });
    };

    loadDynamicImports();
  }, []);
  
  return content ? (
    <header className="header-wrapper" id={id}>
      <div
        style={{
          backgroundColor: '#F4F8FF'
        }}
      >
        <div className="container">
          <div className="row d-flex">
            <div className="col-12">
              <div className="d-flex mt-4">
                <div>
                  <ResponsiveImage
                    img1={logo2030GrayWebp}
                    img={logo2030Gray}
                    w={'100%'} alt={'vision 2030'} />
                </div>
                <div className="d-flex align-items-center px-2">
                  <p className="mb-0 logo2030Text f_size_12_pt">
                    {messages.landingPage.header.title}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="text-wrapper">
                <h1 className="blue font-bold f_size_48 mb-1">مستند</h1>
                <h2 className="darkBlue font-bold">{content.SubTitle}</h2>
                <p className="darkBlue mb-5 f_size_14_pt w-90">
                  {content.Body}
                </p>
                <ButtonComponent
                  content={
                    <>
                      <span className="pe-4 mb-6">{content.tryButton.text}</span>
                      <LeftArrowIcon
                        fill={"#ffff"}
                        height={"16"}
                        width={"8"}
                      />
                    </>
                  }
                  padding="10px 30px"
                  className="me-2 f_size_13_pt mb-4"
                  onClick={dynamicImports?.navigateToFreeTrail}
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="header-img-style"></div>
               {/* <link rel="preload" as="image" itemprop="url" href="https://dev-api.mustand.co/lp-img/2.webp" imagesrcset="https://dev-api.mustand.co/lp-img/2.png , https://dev-api.mustand.co/lp-img/2.webp"/> */}

              {/* <ResponsiveImage
                id='headerImg'
                img1={'https://dev-api.mustand.co/lp-img/2.webp'}
                img={'https://dev-api.mustand.co/lp-img/2.png'}
                // fetchpriority="high"
                loading="eager"
                // img1={content.imgwebpUrl}
                // img={content.img1Url}
                w={'100%'} 
                alt={'dispaly cases in mustand'}
                /> */}

              {/* <div style={{
                'background': `-webkit-image-set(
          url(${content.img1Url}),
          url(${content.imgwebpUrl}))`,

                'background': `image-set(
            url(${content.img1Url}),
            url(${content.imgwebpUrl}))`,

                'background': `url(${content.imgwebpUrl})`,
                'backgroundSize': "contain",
                'backgroundRepeat': "no-repeat",
                'objectFit': "contain",
                'minHeight': '300px',
                'height': "100%"
              }}>

              </div>  */}
            </div>
          </div>
        </div>
      </div>
    </header>
  ) : null;
};
export default injectIntl(Header);
