import React from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { newWhiteLogo } from "../../utils/Images";
import "./layout.scss";
function AuthLayout(props) {
  const {
    intl: { messages },
    children,
  } = props;
  const {
    auth: { expired },
  } = useSelector((state) => state);
  return children.props.newHeader ? (
    <div>
      <header
        id="authHeader"
        style={expired ? { minHeight: "225px" } : {}}
        className={`text-right`}
      >
        <div className="container ">
          <div className='text-right'>
            <img src={newWhiteLogo} alt="logo" width={200}/>
          </div>
          <div className="mainAuthHeader">
            <div id="authFormContainer-newDesign" className="card">
              {children}
            </div>
          </div>
        </div>
      </header>
    </div>
  ) : (
    <div>
      <header
        id="authHeader"
        style={expired ? { minHeight: "auto" } : {}}
        className={`d-flex flex-column align-items-center ${
          children.props.noHeader && "d-none"
        }`}
      >
        <div className="">
          <img src={newWhiteLogo} alt="logo" width={180} />
        </div>
        <h2>{messages.login.welcomeTo}</h2>
        <h2>{messages.login.professionalDashboardForLaw}</h2>
        <p>{messages.login.findThousandCases}</p>
      </header>
      {children}
    </div>
  );
}

export default injectIntl(AuthLayout);
