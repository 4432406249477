import * as types from "./types";

export const loader = (isLoading) => {
  return isLoading
    ? {
        type: types.SHOW_LOADER,
        data: isLoading,
      }
    : {
        type: types.HIDE_LOADER,
        data: isLoading,
      };
};

export const disableLoader = (payload) => ({
  type: types.DISABLE_LOADER,
  data: payload,
});

export const setNavHeight = (payload) => ({ 
  type: types.SET_Nav_HEIGHT, payload 
});

