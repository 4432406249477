import * as types from "./types";

const INITIAL_STATE = {
  list: {},
  sortAndFilter: {},
  sortKey: null,
  filterKey: null,
  session: {},
  pdf:null
};

export default function sessions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_SESSIONS_LIST:
      return {
        ...state,
        list: {
          ...action.payload,
          ...(action.payload?.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.list?.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },
      };
    case types.SORT_AND_FILTER_SESSIONS_RECEIVE:
      return {
        ...state,
        sortAndFilter: action.payload,
      };
    case types.ADD_SORT_AND_FILTER_SESSIONS_KEYS:
      return {
        ...state,
        sortKey: action.payload.sortKey || state.sortKey,
        filterKey: action.payload.filterKey || state.filterKey,
      };
    case types.GET_SESSION_RECEIVE:
      return {
        ...state,
        session: action.payload,
      };
    case types.GENERATE_SESSION_REPORT_RECEIVE:
      return {
        ...state,
        pdf: action.payload,
      };
    default:
      return state;
  }
}
