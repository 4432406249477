// Replace with your request types
export const CONTACTS_LIST_REQUEST = "CONTACTS_LIST_REQUEST";
export const CONTACTS_LIST_RECIEVE = "CONTACTS_LIST_RECIEVE";
export const CONTACTS_DDL_LIST_RECIEVE = "CONTACTS_DDLLIST_RECIEVE"
//creat new contacts
export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
//get single contact  and update
export const GET_CONTACT = "GET_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
