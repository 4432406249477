import * as types from "./types";

const INITIAL_STATE = {
  showLoader: false,
  disableLoader: false,
  navHeight: null,
};

export default function loader(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SHOW_LOADER:
    case types.HIDE_LOADER:
      return { ...state, showLoader: action.data };
    case types.DISABLE_LOADER:
      return { ...state, disableLoader: action.data };
    case types.SET_Nav_HEIGHT:
      return {...state, navHeight: action.payload};
    default:
      return state;
  }
}
