import * as React from 'react';
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popper: {
    backgroundColor: "black",
    fontSize: "16px",
    color: "white",
  },
  arrow: {
    color: "black"
  },
  
}));

  
  const AnchorElTooltips = ({ children, msg }) => {
  const classes = useStyles();
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <Tooltip
      title={msg}
      placement="top"
      arrow
      classes={{arrow: classes.arrow, tooltip: `${classes.popper}`}}
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x,
              areaRef.current.getBoundingClientRect().y,
              0,
              0,
            );
          },
        },
      }}
    >
      <span
        ref={areaRef}
        onMouseMove={handleMouseMove}
      >
        {children}
      </span>
    </Tooltip>
  );
}

export default AnchorElTooltips;