import { getSubDomain } from "../../utils/Shared";
import * as types from "./types";

export default function attachments(state = {}, action) {
  switch (action.type) {
    case types.CASE_ATTACHMENT_RECEIVE:
    case types.FOLDER_ATTACHMENT_RECEIVE:
      return {
        ...state,
        attachments: action.payload || [],
        defaultFolders: types.CASE_ATTACHMENT_RECEIVE ? action.payload : [],
      };
    default:
      return state;
  }
}
