import * as types from "./types";

const INITIAL_STATE = {
  list: {result: []},
  DDLlist: [],
  contact:{}
};

export default function contacts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CONTACTS_DDL_LIST_RECIEVE:
      return {
        ...state,
        DDLlist: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.DDLlist.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },
      };
    case types.CONTACTS_LIST_RECIEVE:
      return {
        ...state,
        list:  {
          ...action.payload,
          ...(action.payload?.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.list?.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },

      };
      case types.ADD_CONTACT:
      return {
        ...state,
        contact: { ...action.payload },
      };
    default:
      return state;
  }
}
