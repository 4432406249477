import React from "react";
import { Route } from "react-router-dom";
import History from "../../routes/History";
import Layout from "../../components/Layout/MainLayout";
import Auth from "../../utils/Auth";
import AuthLayout from "../../components/Layout/AuthLayout";
import { getSubDomain } from "../../utils/Shared";

export const WithLayout = (component, layout) => {
  return layout === "auth" ? (
    <AuthLayout>{component}</AuthLayout>
  ) : (
    <Layout>{component}</Layout>
  );
};

const SuperAdminRoute = ({ component: Component, path, layout, ...rest }) => {
  const lang = localStorage.getItem("lang");
  return (
    <Route
      {...rest}
      render={(props) => {
        if (Auth.isAuth() && !getSubDomain()) {
          return WithLayout(<Component {...props} />, layout);
        } else {
          History.push("/login");
          return null;
        }
      }}
    />
  );
};

export default SuperAdminRoute;
