import * as types from "./types";

const INITIAL_STATE = {
  firmsList: {},
  emailErrIndexList: [],
  speedContractsList: {},
  speedContractsClients: {},
  speedContractsAssignees: {},
  speedContractsTypes: [],
  speedContractsStatus: [],
  contractDetails: {},
  displayContract: {},
  suggestedContractTerms: {},
  usersRoles: [],
  usersJobTitles :[],
  usersList :[],
};

export default function tenants(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FIRMS_RECIEVE:
      return {
        ...state,
        firmsList: action.payload,
      };
    case types.CHECK_IF_CLIENT_MAIL_IS_TAKEN_RECIEVE:
      return {
        ...state,
        emailErrIndexList: action.payload,
      };
    case types.GET_SPEED_CONTRACTS_LIST:
      return {
        ...state,
        speedContractsList: action.payload,
      };

    case types.GET_SPEED_CONTRACTS_CLIENTS:
      return {
        ...state,
        speedContractsClients: {
          ...action?.payload,
          ...(action?.payload?.pagination &&
            action?.payload?.pagination?.current_page !== 1
            ? {
              result: [
                ...(state.speedContractsClients.result || []),
                ...(action.payload.result || []),
              ],
            }
            : {}),
        },
      };
    case types.GET_SPEED_CONTRACTS_ASSIGNEES:
      return {
        ...state,
        speedContractsAssignees: {
          ...action?.payload,
          ...(action?.payload?.pagination &&
            action?.payload?.pagination?.current_page !== 1
            ? {
              result: [
                ...(state.speedContractsAssignees.result || []),
                ...(action.payload.result || []),
              ],
            }
            : {}),
        },
      };
    case types.GET_SPEED_CONTRACTS_TYPES:
      return {
        ...state,
        speedContractsTypes: action.payload
      };
      case types.GET_ADMIN_USERS_LIST:
        return {
          ...state,
          usersList: action.payload,
        };
      case types.GET_USERS_ROLES:
        return {
          ...state,
          usersRoles: action.payload
        };

        case types.GET_USERS_JOB_TITLES:
          return {
            ...state,
            usersJobTitles: action.payload
          };
    case types.GET_SPEED_CONTRACTS_STATUS:
      return {
        ...state,
        speedContractsStatus: action.payload
      };
    case types.GET_SPEED_CONTRACT_DETAILS:
      return {
        ...state,
        contractDetails: action.payload
      };
    case types.PREVIEW_CONTRACT:
      return {
        ...state,
        displayContract: action.payload
      };
    case types.GET_SUGGESTED_CONTRACT_TERMS:
      return {
        ...state,
        suggestedContractTerms: action.payload
      };
    default:
      return state;
  }
}
