import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  years: [],
  currentViewInDashboard: null,
  calenderList: [],
  recentTasks: {},
  appointment: {},
  calendarFilterValues: { events: "all", permission: "all" },
  sortAndFilterTasks: {},
  invoices: {},
  recentComments: [],
  recentActivity: [],
  mainMatrics: [],
  caseMatrics: [],
  verdictMatrics: [],
  sessionMatrics: [],
  clientMatrics: {},
  taskMatrics: [],
};

//get range of years from min to max //ex. [2019,..,..,2022]
const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};
//create years array options
const createYears = (years) => {
  const newYearsArr = range(Number(years[0]), Number(years[1]));
  return newYearsArr.map((year, i) => {
    return {
      id: year,
      title: year,
    };
  });
};
export default function dashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_STATISTICS:
      return {
        ...state,
        list: action.payload,
      };
    case types.ADD_YEARS:
      return {
        ...state,
        years: createYears(action.payload),
      };
    case types.CURRENT_VIEW_IN_DASHBOARD:
      return {
        ...state,
        currentViewInDashboard: action.payload,
      };
    case types.GET_CALENDER_LIST:
      return {
        ...state,
        calenderList: action.payload,
      };
    case types.GET_RECENT_ADDED_TASKS:
      return {
        ...state,
        recentTasks: action.payload,
      };
    case types.VIEW_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      };
    case types.CALENDAR_FILTER_VALUES:
      return {
        ...state,
        calendarFilterValues: action.payload,
      };
    case types.SORT_AND_FILTER_TASKS:
      return {
        ...state,
        sortAndFilterTasks: action.payload,
      };
    case types.GET_DUE_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case types.GET_RECENT_COMMENTS:
      return {
        ...state,
        recentComments: action.payload,
      };
    case types.GET_RECENT_OPENED:
      return {
        ...state,
        recentActivity: action.payload,
      };
    case types.GET_MAIN_MATRICS:
      return {
        ...state,
        mainMatrics: action.payload,
      };
    case types.GET_CASE_CLASS_MATRICS:
      return {
        ...state,
        caseMatrics: action.payload,
      };
    case types.GET_VERDICT_MATRICS:
      return {
        ...state,
        verdictMatrics: action.payload,
      };
    case types.GET_SESSION_MATRICS:
      return {
        ...state,
        sessionMatrics: action.payload,
      };
    case types.GET_CLIENT_MATRICS:
      return {
        ...state,
        clientMatrics: action.payload,
      };
      case types.GET_TASKS_MATRICS:
      return {
        ...state,
        taskMatrics: action.payload,
      };
    default:
      return state;
  }
}
