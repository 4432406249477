import * as types from "./types";

export const loginRequest = (payload) => ({
  type: types.LOGIN_REQUEST,
  payload,
});

export const loginReceive = (payload) => ({
  type: types.LOGIN_RECEIVE,
  payload,
});

export const logoutRequest = (payload) => ({
  type: types.LOGOUT_REQUEST,
  payload,
});

export const checkIfTenantVerifiedRequest = (payload) => ({
  type: types.CHECK_IF_TENANT_VERIFIED_REQUEST,
  payload,
});

export const checkIfTenantVerifiedRecieve = (payload) => ({
  type: types.CHECK_IF_TENANT_VERIFIED_RECIEVE,
  payload,
});

export const setIsTenant = (payload) => ({
  type: types.IS_TENANT,
  payload,
});

export const setTenantName = (payload) => ({
  type: types.SET_TENANT_NAME,
  payload,
});

export const GenerateNewRoutes = (payload) => ({
  type: types.GENERATE_ROUTES,
  payload,
});
export const CheckEmailValidation = (payload) => ({
  type: types.CHECK_EMAIL_VALIDATION,
  payload,
});
export const CheckEmailValidationRecieve = (payload) => ({
  type: types.CHECK_EMAIL_VALIDATION_RECIEVE,
  payload,
});
export const CheckEmailVerification = (payload) => ({
  type: types.CHECK_EMAIL_VERIFICATION,
  payload,
});
export const CheckEmailVerificationRecieve = (payload) => ({
  type: types.CHECK_EMAIL_VERIFICATION_RECIEVE,
  payload,
});

export const ResetPasswordRequest = (payload) => ({
  type: types.RESET_PASSWORD,
  payload,
});

export const isFreeTrialExpired = (payload) => ({
  type: types.IS_FREE_TRIAL_EXPIRED,
  payload,
});

export const deviceType = (payload) => ({
  type: types.DEVICE_TYPE,
  payload,
});
