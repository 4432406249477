import * as types from "./types";

const INITIAL_STATE = {
  casesList: {},
};

export default function SelectList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_CASES:
      return {
        ...state,
        casesList: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
              result: [
                ...(state.casesList.result || []),
                ...(action.payload?.result || []),
              ],
            }
            : {}),
        },
      };
    default:
      return state;
  }
}
