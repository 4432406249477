import * as types from "./types";
const INITIAL_STATE = {
  companies: {},
};

export default function companies(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.COMPANIES_RECEIVE:
      return {
        ...state,
        companies: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.companies.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },
      };
    default:
      return state;
  }
}
