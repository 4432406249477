import { lazy } from "react";

const AddFileIcon = lazy(() => import( "./addFile"));
const AttachmentIcon = lazy(() => import( "./attachment"))
const BriefCaseIcon = lazy(() => import( "./briefCase"));
const Calender = lazy(() => import( "./calender"));
const CalenderCheck = lazy(() => import( "./calenderCheck"));
const CalenderClock = lazy(() => import( "./calenderClock"));
const DocumentIcon = lazy(() => import( "./document"));
const DownloadIcon = lazy(() => import( "./download"));
const PortraitIcon = lazy(() => import( "./portrait"));
const BellRing = lazy(() => import( "./bellRing"));
const Hammer = lazy(() => import( "./hammer"));
const Paper = lazy(() => import( "./paper"));
const Report = lazy(() => import( "./report"));
const CheckList = lazy(() => import( "./checkList"));
const User = lazy(() => import( "./user"));
const SessionReport = lazy(() => import( "./sessionReport"));
const CommentIcon = lazy(() => import( "./comment"));
const Eye = lazy(() => import( "./eye"));

export {
  DocumentIcon,
  AddFileIcon,
  AttachmentIcon,
  BriefCaseIcon,
  Calender,
  CalenderCheck,
  CalenderClock,
  DownloadIcon,
  PortraitIcon,
  BellRing,
  Hammer,
  Paper,
  Report,
  CheckList,
  User,
  SessionReport,
  CommentIcon,
  Eye
};
