import * as types from "./types";

export default function Drawer(state = {}, action) {
  switch (action.type) {
    case types.DRAWER_IS_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
}
