export const GET_ACTIVITY_LOGS_LIST_REQUEST = "GET_ACTIVITY_LOGS_LIST_REQUEST";
export const GET_ACTIVITY_LOGS_LIST = "GET_ACTIVITY_LOGS_LIST";

export const GET_ACTIVITY_LOGS_ACTIONS_REQUEST = "GET_ACTIVITY_LOGS_ACTIONS_REQUEST";
export const GET_ACTIVITY_LOGS_ACTIONS = "GET_ACTIVITY_LOGS_ACTIONS";

export const GET_ACTIVITY_LOGS_CLASSES_REQUEST = "GET_ACTIVITY_LOGS_CLASSES_REQUEST";
export const GET_ACTIVITY_LOGS_CLASSES = "GET_ACTIVITY_LOGS_CLASSES";

export const EXPORT_LOGS_LIST_REQUEST = 'EXPORT_LOGS_LIST_REQUEST'
export const EXPORT_LOGS_LIST = 'EXPORT_LOGS_LIST'