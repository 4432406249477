import * as types from "./types";

const INITIAL_STATE = {
  contracts_status_count: [],
  contracts_list: {},
  contract_status: [],
};

export default function Contracts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_CONTRACTS_STATUS_COUNT:
      return {
        ...state, contracts_status_count: action.payload
      };
      case types.GET_CONTRACTS_LIST:
        return {
          ...state, contracts_list: action.payload
        };
        case types.GET_CONTRACT_STATUS:
        return {
          ...state, contract_status: action.payload
        };
    default:
      return state;
  }
}
