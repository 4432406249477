import React from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { ClockImg } from "../../utils/Images";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 10000,
    color: "#E0E0E0", // #ddd
  },
}));
const Loader = ({ intl: { messages } }) => {
  const { loader } = useSelector((state) => state);
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={`${classes.backdrop}`} open={loader.showLoader}>
        {/* <CircularProgress color="inherit" /> */}
        <div className="loader">
          <img src={ClockImg} alt="loader" />
          <span className="px-2">{messages.loaderText}</span>
        </div>
      </Backdrop>
    </div>
  );
};

export default injectIntl(Loader);
