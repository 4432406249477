import * as types from "./types";

const INITIAL_STATE = {
  list: {},
  actions_types: [],
  action_classes: []
};

export default function ActivityLogs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_ACTIVITY_LOGS_LIST:
      return {
        ...state, list: action.payload
      };
      case types.GET_ACTIVITY_LOGS_ACTIONS:
        return {
          ...state, actions_types: action.payload
        };
        case types.GET_ACTIVITY_LOGS_CLASSES:
          return {
            ...state, action_classes: action.payload
          };
    default:
      return state;
  }
}
