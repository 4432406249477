export const GET_COMMENTS_LIST = "GET_COMMENTS_LIST";
export const ADD_COMMENTS_LIST = "ADD_COMMENTS_LIST";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_REPLY = "ADD_REPLY";
export const APPEND_NEW_COLLABORATION = "APPEND_NEW_COLLABORATION";
export const GET_COLLABORATION_COUNT = "GET_COLLABORATION_COUNT";
export const ADD_COLLABORATION_COUNT = "ADD_COLLABORATION_COUNT";
export const ADD_REPLY_TO_COMMENT = "ADD_REPLY_TO_COMMENT"
export const MARK_COMMENT_AS_SEEN_REQUEST = 'MARK_COMMENT_AS_SEEN_REQUEST'
export const MARK_COMMENT_AS_SEEN = 'MARK_COMMENT_AS_SEEN'


