// import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import ThemeApp from "./Theme";

import('react-dom').then((ReactDOM) => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeApp />
    </Provider>,
    document.getElementById("root")
  );
})

// ReactDOM.render(
//   <Provider store={store}>
//     <ThemeApp />
//   </Provider>,
//   document.getElementById("root")
// );
