import * as types from "./types";

const INITIAL_STATE = {
  phone_number: '',
  otp: '',
  collect_user_data: {},
  complete_profile: '',
};

export default function ContractsAuth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_PHONE_NUMBER:
      return {
        ...state, phone_number: action.payload
      };
      case types.ADD_OTP:
        return {
          ...state, otp: action.payload
        };
    case types.COLLECT_USER_DATA:
      return {
        ...state, collect_user_data: action.payload
      };
      case types.COMPLETE_PROFILE:
        return {
          ...state, complete_profile: action.payload
        };
    default:
      return state;
  }
}
