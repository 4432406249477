import * as types from "./types";

const INITIAL_STATE = {
  list: {},
};

export default function Collaborations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_COMMENTS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case types.APPEND_NEW_COLLABORATION: //listen
      return {
        ...state,
        list: action.payload
        // list: {
        //   result: appendNewCollaboration(action.payload, state.list),
        //   pagination: {
        //     ...state.list.pagination,
        //     total: action.payload?.parent_id
        //       ? state.list.pagination?.total
        //       : state.list.pagination?.total + 1,
        //   },
        // },
      };
    default:
      return state;
  }
}
