export const CLIENTS_REQUEST = "CLIENTS_REQUEST";
export const CLIENTS_RECEIVE = "CLIENTS_RECEIVE";

export const GET_CLIENTS_LIST_REQUEST = "GET_CLIENTS_LIST_REQUEST";
export const GET_CLIENTS_LIST_RECEIVE = "GET_CLIENTS_LIST_RECEIVE";

export const CLIENTS_IN_CASE_REQUEST = "CLIENTS_IN_CASE_REQUEST";
export const CLIENTS_IN_CASE_RECEIVE = "CLIENTS_IN_CASE_RECEIVE";

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_RECEIVE = "CREATE_CLIENT_RECEIVE";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_RECEIVE = "UPDATE_CLIENT_RECEIVE";

export const GET_SINGLE_CLIENT_REQUEST = "GET_SINGLE_CLIENT_REQUEST";
export const GET_SINGLE_CLIENT_RECEIVE = "GET_SINGLE_CLIENT_RECEIVE";

export const GET_CLIENT_STATUS_REQUEST = "GET_CLIENT_STATUS_REQUEST";
export const GET_CLIENT_STATUS_RECEIVE = "GET_CLIENT_STATUS_RECEIVE";

export const GET_CLIENT_STATUS_COUNT_REQUEST = "GET_CLIENT_STATUS_COUNT_REQUEST";
export const GET_CLIENT_STATUS_COUNT_RECEIVE = "GET_CLIENT_STATUS_COUNT_RECEIVE";

export const CREATE_REPRESENTATIVE_CONTACT_REQUEST = "CREATE_REPRESENTATIVE_CONTACT_REQUEST";

export const UPDATE_REPRESENTATIVE_CONTACT_REQUEST = "UPDATE_REPRESENTATIVE_CONTACT_REQUEST";

export const DELETE_REPRESENTATIVE_CONTACT_REQUEST = "DELETE_REPRESENTATIVE_CONTACT_REQUEST";

export const SET_DEFAULT_CONATCT_REQUEST = 'SET_DEFAULT_CONATCT_REQUEST'

export const GET_SINGLE_CONTACT = 'GET_SINGLE_CONTACT';

export const GET_CLIENT_ATTACHMENTS_REQUEST = "GET_CLIENT_ATTACHMENTS_REQUEST";
export const GET_CLIENT_ATTACHMENTS_RECEIVE = "GET_CLIENT_ATTACHMENTS_RECEIVE";

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';

export const RENAME_FILE_REQUEST = 'RENAME_FILE_REQUEST';

export const UPLOAD_ATTACHMENT_FILE_REQUEST = 'UPLOAD_ATTACHMENT_FILE_REQUEST';

export const PRINT_CLIENT_INFO_REQUEST = 'PRINT_CLIENT_INFO_REQUEST'
export const PRINT_CLIENT_INFO_RECEIVE = 'PRINT_CLIENT_INFO_RECEIVE'

export const CLIENT_TYPE = 'CLIENT_TYPE';

