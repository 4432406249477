export const GET_CONTRACTS_STATUS_COUNT_REQUEST = "GET_CONTRACTS_STATUS_COUNT_REQUEST";
export const GET_CONTRACTS_STATUS_COUNT = "GET_CONTRACTS_STATUS_COUNT";

export const GET_CONTRACTS_LIST_REQUEST = "GET_CONTRACTS_LIST_REQUEST";
export const GET_CONTRACTS_LIST = "GET_CONTRACTS_LIST";

export const GET_CONTRACT_STATUS_REQUEST = "GET_CONTRACT_STATUS_REQUEST";
export const GET_CONTRACT_STATUS = "GET_CONTRACT_STATUS";



export const SIGN_CONTRACT_REQUEST = "SIGN_CONTRACT_REQUEST";
export const SIGN_CONTRACT = "SIGN_CONTRACT";
