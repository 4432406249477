import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./rootReducer";
import createSagaMiddleware from "redux-saga";
import {LandingPageSaga} from './LandingPage/sagas';
const saga = createSagaMiddleware();
//redux dev tool
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(saga));

const store = createStore(reducers, enhancer);

// saga.run(watchSagas);

saga.run(LandingPageSaga);

const handleUncriticalSaga = async() => {
  if(window.location.pathname !== '/'){
    const {watchSagas} = await import('./rootSaga');
    saga.run(watchSagas);
  }
}

handleUncriticalSaga();
// import('./rootSaga').then((module) => {
//   saga.run(module.watchSagas);
// })


export default store;
