import LogRocket from "logrocket";

LogRocket.init("8jf58x/legit");

if (localStorage.getItem("userInfo")) {
  const user = JSON.parse(localStorage.getItem("userInfo"));

  LogRocket.identify(user.id, {
    name: user.name,
    email: user.email,

    // Add your own custom user variables here, ie:
    subscriptionType: `${user.name}_${user.id}`,
  });
}
