import axios from "axios";
import { getSubDomain } from "../utils/Shared";
import { requestHandler, successHandler, errorHandler } from "./interceptors";

//add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL: `https://${
    getSubDomain()
      ? `${getSubDomain()}.${process.env.REACT_APP_API_BASE_URL}`
      : process.env.REACT_APP_API_BASE_URL
  }`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Api-Key": process.env.REACT_APP_API_KEY,
  },
});

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
