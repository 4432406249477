import { useState, useEffect } from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider, StylesProvider, jssPreset  } from "@material-ui/styles";
import rtl from "jss-rtl";
import { create } from "jss";
import { useSelector } from "react-redux";
import App from "./containers/App";

function ThemeApp() {
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const lang = useSelector((state) => state.lang);
  const [direction, setDirection] = useState(lang === "en" ? "ltr" : "rtl");
  const defaultFontFamilies = [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"DINNextLTArabic-Regular-3"'
  ];
  useEffect(() => {
    setDirection(lang === "en" ? "ltr" : "rtl");
  }, [lang]);
   
  const theme = createTheme({
    direction: direction,
    palette: {
      type: "light",
      primary: {
        main: "#000",
      },
      secondary: {
        main:"#3173F2", //#d09e5c
      },
      background: {
        drawer: "#fff", // "#16456f"
        darkHoney:"#3173F2", //#d09e5c
        lightGreen: "#EAF1FE",
        icon: "#F9CDA6", //#fd8c46
        lightGrey: "#EAF1FE", //#f8f8f8
        orange: "#F2994A", //#eb7c38
        seaGreen: "#3173F2", // #1db49d  
        lightSeaGreen: "#1db49d1a", 
        lightRed: "#FFEDE9", // #f7dee6 
        transparent: "transparent",
        lightOrange: "#FFF3E9", //#e37029
        lightBlue: "#6ac6e81a",
        lightBlue2: "#3173F2",  
        lightPurple: "#605bff1a", 
        limeGreen: "#38c3381a", 
        fadeRed: "#e86a6a1a", 
        fadeGrey: "#F2F2F2", // #f1f1f5 
        darkBlue: "#5990F4", // #1d7ab4
        coralPink: "#3173F2", //#fb8a7b
        blue:"#5990F4", // #1d7ab4
        red:"#FF897A", //#e86a6a
        white:'#FFFFFF',
        paleBlue:'#EAF1FE',
        tableHead:"#FCFCFC", //#EFF2F8,
        paleGray: "#E0E0E0", 
        darkRed :"#E44D47", 
        lightingBlue:'#43CBEE'
      },
      text: {
        activeNav:"#3173F2",
        inActiveNav:" #828282",
        light: "#F8F8F8", // #fff
        lightBlue: "#5990F4", // #1d7ab4
        lightBlue2: "#3173F2", //#16456F
        ashGrey: "#BDBDBD",  // #adb2be
        seaGreen: "#27AE60", // #1db49d 
        orange: "#F68D2F", //#eb7c38
        purple: "#605bff",
        blue: "#ACC7F9", // #6ac6e8
        green: "#6FCF97", // #38c338
        red: "#e86a6a", 
        grey: "#828282", // #92929D
        tableHead:"#001E55",
        darkGray: "#333333",
        tableCell:"#001E55",
        soPaleGray:"#CED4DA",
        lightBlack:"#4F575E",
        errorRed :"#E44D47",
      },
    },
    typography: {
      fontFamily:
        lang === "en"
          ? ["Roboto-Regular", ...defaultFontFamilies].join(",")
          : ["DINNextLTArabic-Regular-3", ...defaultFontFamilies].join(","),
      button: {
        fontSize: "1rem",
        fontWeight: 600,
        textTransform: "none",
      },
    },
  });
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  );
}

export default ThemeApp;
