// Replace with your request types
export const CREATE_CASE_REQUEST = "CREATE_CASE_REQUEST";
export const SEND_FILES_REQUEST = "SEND_FILES_REQUEST";
export const UPLOAD_COMPANY_LOGO_REQUEST = "UPLOAD_COMPANY_LOGO_REQUEST";
export const UPLOAD_CLIENT_PIC_REQUEST = "UPLOAD_CLIENT_PIC_REQUEST";

export const ADD_NEW_CASE_CONTENT = "ADD_NEW_CASE_CONTENT";
export const ADD_UPLOAD_CASE_PART_SUCCESS = "ADD_UPLOAD_CASE_PART_SUCCESS";

export const GET_CASES_LIST = "GET_CASES_LIST";
export const ADD_CASES_LIST = "ADD_CASES_LIST";

export const GET_CASE_DETAILS = "GET_CASE_DETAILS";
export const ADD_CASE_DETAILS = "ADD_CASE_DETAILS";

export const GET_CASE_DETAILS_V2 = "GET_CASE_DETAILS_V2";
export const ADD_CASE_DETAILS_V2 = "ADD_CASE_DETAILS_V2";

export const GET_CASES_TASKS_COUNTS = "GET_CASES_TASKS_COUNT";
export const ADD_CASES_TASKS_COUNTS = "ADD_CASES_TASKS_COUNT";
// Case admin(manager) list
export const GET_CASE_ADMIN_LIST = "GET_CASE_ADMIN_LIST";
export const ADD_CASE_ADMIN_LIST = "ADD_CASE_ADMIN_LIST";

//History form -request
export const CASE_HISTORY_REQUEST = "CASE_HISTORY_REQUEST";
export const CASE_HISTORY_RECIEVE = "CASE_HISTORY_RECIEVE";
//History list -request
export const CASE_HISTORY_LIST_REQUEST = "CASE_HISTORY_LIST_REQUEST";
export const CASE_HISTORY_LIST_RECIEVE = "CASE_HISTORY_LIST_RECIEVE";
//Parties list -request
export const CASE_PARTIES_LIST_REQUEST = "CASE_PARTIES_LIST_REQUEST";
export const CASE_PARTIES_LIST_RECIEVE = "CASE_PARTIES_LIST_RECIEVE";
//delete attach with id
export const DELETE_ATTACH_WITH_ID_REQUEST = "DELETE_ATTACH_WITH_ID_REQUEST";
//delete attach without id
export const DELETE_ATTACH_REQUEST = "DELETE_ATTACH_REQUEST";
//get single case  and update
export const GET_CASE = "GET_CASE";
export const ADD_CASE = "ADD_CASE";
export const UPDATE_CASE = "UPDATE_CASE";

//filter values
export const ADD_FILTER_VALUES = "ADD_FILTER_VALUES";
export const RESET_FILTER_VALUES = "RESET_FILTER_VALUES";


export const GET_CASES_STATISTICS = "GET_CASES_STATISTICS";
export const ADD_CASES_STATISTICS = "ADD_CASES_STATISTICS";

export const ADD_FILTER_VALUE = "ADD_FILTER_VALUE"; 
export const ADD_FILTER_BY_CARDS_VALUES = "ADD_FILTER_BY_CARDS_VALUES";

export const DELETE_CASE = "DELETE_CASE";
export const DECISION_REQUEST = "DECISION_REQUEST";

export const UPDATE_DECISION = "UPDATE_DECISION";
export const DELETE_DECISION = "DELETE_DECISION";

export const ADD_RELATION = "ADD_RELATION";
export const DELETE_RELATION = "DELETE_RELATION";

export const GET_RELATIONS_REQUEST = "GET_RELATIONS_REQUEST";
export const GET_RELATIONS_RESPONSE = "GET_RELATIONS_RESPONSE";

export const EXPORT_CASES_REQUEST = "EXPORT_CASES_REQUEST";
export const EXPORT_CASES_RESPONSE = "EXPORT_CASES_RESPONSE";

export const EXPORT_CASE_SUMMARY_IN_PDF_REQUEST = "EXPORT_CASE_SUMMARY_IN_PDF_REQUEST";
export const EXPORT_CASE_SUMMARY_IN_PDF_RESPONSE = "EXPORT_CASE_SUMMARY_IN_PDF_RESPONSE";

export const EXPORT_CASE_DETAILS_IN_PDF_REQUEST = "EXPORT_CASE_DETAILS_IN_PDF_REQUEST";
export const EXPORT_CASE_DETAILS_IN_PDF_RESPONSE = "EXPORT_CASE_DETAILS_IN_PDF_RESPONSE";

export const REMINDERS_LIST = "REMINDERS_LIST";

