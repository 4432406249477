import * as types from "./types";

const INITIAL_STATE = {
  taxes: [],
  items: [],
  list: [],
  invoice: {},
  pdfLink: "",
  statistics: [],
  filterKey:[],
  cardsKey:{},
};

export default function Invoices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_TAXES:
      return {
        ...state,
        taxes: action.payload,
      };
    case types.ADD_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case types.ADD_INVOICES:
      return {
        ...state,
        list: action.payload,
      };
    case types.ADD_INVOICES_FILTER_VALUES:
      return {
        ...state,
        filterKey: action.payload,
      };
    case types.ADD_INVOICES_CARDS_VALUE:
      return {
        ...state,
        cardsKey: action.payload,
      };
    case types.ADD_INVOICES_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    case types.ADD_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    case types.ADD_PDF_LINK:
      return {
        ...state,
        pdfLink: action.payload,
      };
    default:
      return state;
  }
}
