// Replace with your request types
export const GET_NOTIFICATIONS_LIST = "GET_NOTIFICATIONS_LIST";
export const ADD_NOTIFICATIONS_LIST = "ADD_NOTIFICATIONS_LIST";

export const MARK_NOTIFICATION_AS_READ_REQUEST =
  "MARK_NOTIFICATION_AS_READ_REQUEST";
export const MARK_NOTIFICATION_AS_READ_RECIEVE =
  "MARK_NOTIFICATION_AS_READ_RECIEVE";

export const MARK_ALL_NOTIFICATION_AS_READ_REQUEST =
  "MARK_ALL_NOTIFICATION_AS_READ_REQUEST";

export const APPEND_NEW_NOTIFICATION = "APPEND_NEW_NOTIFICATION";
//notifications count
export const GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT";
export const ADD_NOTIFICATIONS_COUNT = "ADD_NOTIFICATIONS_COUNT";
