export const CREATE_TAX = "CREATE_TAX";
export const GET_TAXES = "GET_TAXES";
export const ADD_TAXES = "ADD_TAXES";

export const CREATE_ITEM = "CREATE_ITEM";
export const GET_ITEMS = "GET_ITEMS";
export const ADD_ITEMS = "ADD_ITEMS";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const GET_INVOICES = "GET_INVOICES";
export const ADD_INVOICES = "ADD_INVOICES";

export const GET_INVOICES_FILTER_VALUES = "GET_INVOICES_FILTER_VALUES";
export const ADD_INVOICES_FILTER_VALUES = "ADD_INVOICES_FILTER_VALUES";

export const GET_INVOICES_CARDS_VALUE = "GET_INVOICES_CARDS_VALUE";
export const ADD_INVOICES_CARDS_VALUE = "ADD_INVOICES_CARDS_VALUE"; 

export const GET_INVOICES_STATISTICS = "GET_INVOICES_STATISTICS";
export const ADD_INVOICES_STATISTICS = "ADD_INVOICES_STATISTICS";

export const GET_INVOICE = "GET_INVOICE";
export const ADD_INVOICE = "ADD_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";

export const GENERATE_PDF = "GENERATE_PDF";
export const ADD_PDF_LINK = "ADD_PDF_LINK";

export const ISSUE_INVOICE = "ISSUE_INVOICE";
export const CANCEL_INVOICE = "CANCEL_INVOICE";
export const PAY_INVOICE = "PAY_INVOICE";
