import * as types from "./types";

const INITIAL_STATE = {
  list: {},
  task: {},
  sortAndFilter: {},
  sortKey: null,
  filterKey: null,
};

export default function tasks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_TASKS_LIST:
      return {
        ...state,
        list: {
          ...action.payload,
          ...(action.payload?.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.list?.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },
      };
    case types.ADD_TASK:
      return {
        ...state,
        task: { ...action.payload },
      };
    case types.SORT_AND_FILTER_RECEIVE:
      return {
        ...state,
        sortAndFilter: action.payload,
      };
    case types.ADD_SORT_AND_FILTER_KEYS:
      return {
        ...state,
        sortKey: action.payload.sortKey || state.sortKey,
        filterKey: action.payload.filterKey || state.filterKey,
      };
    default:
      return state;
  }
}
