import * as types from "./types";

export default function sorting(state = {sort_data: [], sort_columns: []}, action) {
  switch (action.type) {
    case types.SORT_DATA:
      return {
        ...state, sort_data: action.payload
      };
      case types.SORT_COLUMNS:
        return {
          ...state, sort_columns: Array.isArray(action.payload) ? [...action.payload] : [...state.sort_columns, action.payload] 
        };
    default:
      return state;
  }
}
