import * as types from "./types";

const INITIAL_STATE = {
  generalBreadcrumbs: [],
  navbarBreadcrumb: "",
  customBreadcrumb: {}
};

export default function breadcrumbs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_BREADCRUMB:
      return {
        ...state,
        generalBreadcrumbs: action.payload,
      };
    case types.ADD_NAVBAR_BREADCRUMB:
      return {
        ...state,
        navbarBreadcrumb: action.payload,
      };
      case types.ADD_CUSTOM_BREADCRUMB:
        return {
          ...state,
          customBreadcrumb: action.payload,
        };
    default:
      return state;
  }
}
