import * as types from "./types";

const INITIAL_STATE = {otp:''};

export default function FreeTrail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.VERIFY_OTP_RECEIVE:
      return {
        otp: action.payload,
      };
    default:
      return state;
  }
}
