import * as types from "./types";

const INITIAL_STATE = {
  list: {},
  history: {},
};

export default function History(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CASE_HISTORIES_LIST_RECIEVE:
      return {
        ...state,
        list: {
          ...action.payload,
          ...(action.payload?.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.list?.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },
      };
    case types.ADD_HISTORY:
      return {
        ...state,
        history: { ...action.payload },
      };
    default:
      return state;
  }
}
