// Replace with your request types
export const ASSIGNED_CASE_MEMBERS_REQUEST = "ASSIGNED_CASE_MEMBERS_REQUEST";
export const ASSIGNED_CASE_MEMBERS_RECIEVE = "ASSIGNED_CASE_MEMBERS_RECIEVE";

export const NON_ASSIGNED_CASE_MEMBERS_REQUEST =
  "NON_ASSIGNED_CASE_MEMBERS_REQUEST";
export const NON_ASSIGNED_CASE_MEMBERS_RECIEVE =
  "NON_ASSIGNED_CASE_MEMBERS_RECIEVE";

export const ASSIGN_CASE_TEAM_MEMBERS_REQUEST =
  "ASSIGN_CASE_TEAM_MEMBERS_REQUEST";

export const UNASSIGN_CASE_TEAM_MEMBER_REQUEST =
  "UNASSIGN_CASE_TEAM_MEMBER_REQUEST";

export const ADD_USERS_SEARCH_VALUE = "ADD_USERS_SEARCH_VALUE";
export const ADD_USERS_FILTER_BY = "ADD_USERS_FILTER_BY";
