import { takeLatest, call, put, all } from "redux-saga/effects";
import * as API from "./apis";
import * as ACTIONS from "./actions";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import History from "../../routes/History";
import * as TYPES from "./types";
import { disableLoader } from "../Loader/actions";
import store from "..";

export function* landingPageRequest({ payload }) {
  try {
    const response = yield call(API.landingPageRequest, payload);
    yield put(ACTIONS.landingPageReceive(response.data?.data));
  } catch (error) {
    dispatchSnackbarError(error.response?.data);
    console.error(error);
  }
}
export function* contactUsRequest({ payload }) {
  const { data, resetForm } = payload;
  try {
    const response = yield call(API.contactUsRequest, payload.data);
    resetForm && resetForm();
    dispatchSnackbarSuccess("contactUsMsgSent");
  } catch (error) {
    dispatchSnackbarError(error.response?.data);
    console.error(error);
  }
}
export function* countriesRequest({ payload }) {
  try {
    yield put(disableLoader(true))
    const response = yield call(API.countriesRequest, payload);
    yield put(ACTIONS.countriesReceive(response.data?.data));
  } catch (error) {
    dispatchSnackbarError(error.response?.data);
    console.error(error);
  }
}
export function* LandingPageSaga() {
  yield takeLatest(TYPES.LANDING_PAGE_REQUEST, landingPageRequest);
  yield takeLatest(TYPES.CONTACT_US_REQUEST, contactUsRequest);
  yield takeLatest(TYPES.COUNTRIES_LANDING_PAGE_REQUEST, countriesRequest);
}
