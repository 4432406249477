
import "./style.scss"
const ResponsiveImage = ({ pictureUi, id, objectFit, img1, img1Media, img2, img2Media, img, h, w, alt, loading, mobileSize, tabletSize, webSize, ...rest }) => {
    return (
        <picture className={pictureUi}>
            <img
                id={id}
                loading={loading || 'lazy'}
                src={img} 
                alt={alt}
                width={w} 
                height={h}
                srcSet={`
                ${img1 || ''} ${img1Media ? img1Media + 'w' : ''},
                ${img2 || ''} ${img2Media ? img2Media + 'w' : ''}
                `}
                {...rest}
                style={{ "objectFit": objectFit ? objectFit : "contain" }}
            />
        </picture>
    )
}

export default ResponsiveImage;