import {lazy} from "react";
import * as types from "./types";
import dayjs from "dayjs";

const INITIAL_STATE = {
  list: {},
  flag: "",
  count: 0,
};

const markNotificationRead = ({ id }, list) => {
  return list.result.map((noti) => {
    if (noti.id === id) {
      noti.read_at = true;
    }

    return noti;
  });
};

const appendNewNotification = (notification, list) => {
  const listCopy = [...list?.result];
  listCopy.unshift(notification);

  return listCopy;
};

export default function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_NOTIFICATIONS_LIST: //list
      return {
        ...state,
        list: {
          ...action.payload,
          ...(action.payload.pagination &&
          action.payload.pagination.current_page !== 1
            ? {
                // Concatenate new pages on previous pages
                result: [
                  ...(state.list.result || []),
                  ...(action.payload.result || []),
                ],
              }
            : {}),
        },
      };
    case types.MARK_NOTIFICATION_AS_READ_RECIEVE:
      return {
        ...state,
        list: {
          ...state.list,
          result: markNotificationRead(action.payload, state.list),
        },
        count: state.count - 1,
      };
    case types.APPEND_NEW_NOTIFICATION: //listen
      return {
        ...state,
        list: {
          ...state.list,
          result: appendNewNotification(
            {
              ...action.payload,
              created_at: action.payload.created_at
                ? action.payload.created_at
                : dayjs(),
            },
            state.list
          ),
        },
        count: state.count + 1,
      };
    case types.ADD_NOTIFICATIONS_COUNT: //count
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
}
