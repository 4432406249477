import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import { hideSnackbarAction } from "../../store/Snackbar/actions";
import { WarningIcon, TrueSign } from "../../utils/Images";
import { injectIntl } from "react-intl";
import "./snackbar.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MaterialSnackbar(props) {
  const { isOpen, message, type,  action,
    handleActionClick,
    actionStyle, } = useSelector((state) => state.snackbar);
  const {
    intl: { messages },
  } = props;
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbarAction());
  };

  return (
    <div className="snackbarWrapper">
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      key={`top,left`}
      onClose={handleClose}
      className={`${type === "error" ? "error" : "success"}`}
    >
      <Alert
        icon={<img src={type === "error" ? WarningIcon : TrueSign} alt="icon" />}
        onClose={handleClose}
        severity={type}
        className="medium_font d-flex align-items-center"
      >
          <p className={`mb-0 f_size_14`}>
            {messages.errors[message] || message}
          </p>
          {action && (
            <p
              onClick={handleActionClick}
              className={`mb-0 action ${actionStyle}`}
            >{action}</p>
          )}
      </Alert>
    </Snackbar>
    </div>
  );
}

export default injectIntl(MaterialSnackbar);
