import React from "react";
import { Route } from "react-router-dom";
import History from "../../routes/History";
import LandingPageLayout from "../../components/Layout/LandingPageLayout";
import {
  removePersonalDataFromURL,
  setCookie,
} from "../../utils/Shared";

export const WithLayout = (component) => {
  return <LandingPageLayout>{component}</LandingPageLayout>
};
const LandingPageRoute = ({ component: Component, path, ...rest }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");
  if (History.location.search) {
    removePersonalDataFromURL(History.location.pathname);
    setCookie("userInfo", userInfo);
    setCookie("token", token);
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // if (getSubDomain() && !Auth.isAuth()) {
        //   History.push(`/login`);
        //   return null;
        // } else if (getSubDomain() && Auth.isAuth() && getCookie("token")) {
        //   History.push(`/dashboard`);
        //   return null;
        // } else {
        //   if (!getCookie("token")) {
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("userInfo");
        //   } 
        //   return WithLayout(<Component {...props} {...rest} />);
        // }
        return WithLayout(<Component {...props} {...rest} />);
      }}
    />
  );
};

export default LandingPageRoute;
