import * as types from "./types";

const INITIAL_STATE = {
  details: [],
  party: {},
};

export default function parties(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.PARTIES_LIST_RECIEVE:
      return {
        ...state,
        list: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.list?.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },
      };
    case types.ADD_PARTIES:
      return {
        ...state,
        party: action.payload,
      };
    default:
      return state;
  }
}
