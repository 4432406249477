import * as types from './types';

export const showSnackbarAction = (message , snacknarType, action, handleActionClick, actionStyle) => {
  return {
  type: types.SHOW_SNACKBAR, 
  message ,
  snacknarType,
  action,
  handleActionClick,
  actionStyle
  };
};

export const hideSnackbarAction = () => {
  return {
   type: types.HIDE_SNACKBAR 
  };
};