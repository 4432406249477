import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";
import "../../containers/LandingPage/styles.scss";

function LandingPageLayout(props) {
  const {
    intl: { messages },
    children,
  } = props;
  const { loader } = useSelector(state => state)
  const navOffsetHeight = document.getElementById('landingpageNav')?.clientHeight
  const [navHeight, setNavHeight] = useState(navOffsetHeight || '60');

  useEffect(() => {
    if (document.getElementById('landingpageNav')?.clientHeight > 0) {
      setNavHeight(navOffsetHeight)
    }
  }, [document.getElementById('landingpageNav')?.clientHeight]);

  useEffect(() => {
    setNavHeight(loader?.navHeight)
  }, [loader?.navHeight]);

  return (
    <div>
      {children.props.seo}
      <Navbar />
      <div
        // className="mt-60"
        style={{ marginTop: `${navHeight}px` }}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default injectIntl(LandingPageLayout);
