// Replace with your request types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RECEIVE = "LOGIN_RECEIVE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const CHECK_IF_TENANT_VERIFIED_REQUEST =
  "CHECK_IF_TENANT_VERIFIED_REQUEST";
export const CHECK_IF_TENANT_VERIFIED_RECIEVE =
  "CHECK_IF_TENANT_VERIFIED_RECIEVE";

export const IS_TENANT = "IS_TENANT";
export const SET_TENANT_NAME = "SET_TENANT_NAME";
export const GENERATE_ROUTES = "GENERATE_ROUTES";
export const CHECK_EMAIL_VALIDATION = "CHECK_EMAIL_VALIDATION";
export const CHECK_EMAIL_VALIDATION_RECIEVE = "CHECK_EMAIL_VALIDATION_RECIEVE";
export const CHECK_EMAIL_VERIFICATION = "CHECK_EMAIL_VERIFICATION";
export const CHECK_EMAIL_VERIFICATION_RECIEVE =
  "CHECK_EMAIL_VERIFICATION_RECIEVE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const IS_FREE_TRIAL_EXPIRED = "IS_FREE_TRIAL_EXPIRED";

export const IS_HIJRI_DATE = 'IS_HIJRI_DATE';
export const DEVICE_TYPE = 'DEVICE_TYPE';