import React, { useEffect, useState, lazy } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/LandingPage/Header";
import Footer from "../../components/LandingPage/Footer";
import { landingPageRequest } from "../../store/LandingPage/actions";
import "./styles.scss";
import "./bootstrapClasses.scss";

// const Header = lazy(() => import('../../components/LandingPage/Header'));
const WhoIsLegit = lazy(() => import('../../components/LandingPage/WhoIsLegit'));
const StartYourJourney = lazy(() => import('../../components/LandingPage/StartYourJourney'));
const EasyUse = lazy(() => import('../../components/LandingPage/EasyUse'));
const RiskOfPapers = lazy(() => import('../../components/LandingPage/RiskOfPapers'));
const DownloadApp = lazy(() => import('../../components/LandingPage/DownloadApp'));

const LandingPage = ({intl:{messages}}) => {
  const dispatch = useDispatch();
  const [sections, setSections] = useState([]);
  const {
    landingPage: { content },
    loader: { showLoader }
  } = useSelector((state) => state);
  useEffect(() => {
    dispatch(landingPageRequest());
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (Object.keys(content).length) {
      setSections([
        // {
        //   component: Header,
        //   content: content?.Home,
        //   id: "dashboard",
        // },
        {
          component: WhoIsLegit,
          content: content?.WhatIsLegit,
          id: "whoIsLegit",
        },
        {
          component: StartYourJourney,
          content: content?.registration,
          id: "registration",
        },
        { component: EasyUse, content: content?.easyUSe, view: "landingPage" },
        {
          component: RiskOfPapers,
          content: content?.riskOFpapers,
        },
        {
          component: DownloadApp,
          content: content?.downloadApp,
        },
      ]);
    }
  }, [content]);
  return (
    <div className="landingPage-wrapper">
      {/* seperating first sectionc(Header) to improve performance */}
      <Header content={content?.Home} id='dashboard'/>
      {Object.keys(content).length ? (
        <>
          {sections.length
            ? sections.map(
                (section, i) =>
                    <section.component
                      id={section.id}
                      content={section.content}
                      key={i}
                    />
                  )
            : null}
        </>
      ) : !showLoader && <p className="networkErrorStyle">{messages.errors.networkError}</p>}
      {window.location.pathname?.includes('contracts') && <Footer />}
    </div>
  );
};

export default injectIntl(LandingPage);
