import React from "react";
const LeftArrowIcon = (props) => {
  return (
    <>
      <svg
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        width={props.width || "8"}
        height={props.height || "16"}
      >
        <path
          d="M9.19403 1.56591C9.19379 1.84761 9.08168 2.11768 8.88237 2.31675L3.44803 7.75108C3.28355 7.91552 3.15307 8.11076 3.06404 8.32564C2.97502 8.54051 2.9292 8.77082 2.9292 9.00341C2.9292 9.236 2.97502 9.46631 3.06404 9.68119C3.15307 9.89607 3.28355 10.0913 3.44803 10.2557L8.87528 15.683C9.06883 15.8834 9.17592 16.1518 9.1735 16.4304C9.17108 16.7089 9.05934 16.9754 8.86234 17.1724C8.66535 17.3694 8.39886 17.4812 8.12027 17.4836C7.84169 17.486 7.5733 17.3789 7.37291 17.1854L1.94566 11.7624C1.21591 11.0312 0.806061 10.0403 0.806061 9.00731C0.806061 7.97427 1.21591 6.98343 1.94566 6.25225L7.37999 0.81437C7.52859 0.66568 7.71795 0.564409 7.92412 0.523372C8.13029 0.482335 8.34399 0.503377 8.5382 0.583835C8.73241 0.664293 8.89838 0.800551 9.01512 0.975366C9.13187 1.15018 9.19413 1.3557 9.19403 1.56591Z"
          {...props}
        />
      </svg>
    </>
  );
};

export default LeftArrowIcon;
