import * as types from "./types";
const INITIAL_STATE = {
  list: {},
  clients_in_case_list: [],
  client_type: 1,
  single_client: {},
  single_contact: {},
  clients_list: [],
  client_status_list: [],
  client_status_count: [],
  client_attachments:[]
};

export default function clients(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_CLIENTS_LIST_RECEIVE:
      return {
        ...state,
        clients_list: action.payload,
      }
    case types.CLIENTS_RECEIVE:
      return {
        ...state,
        list: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
              result: [
                ...(state.list.result || []),
                ...(action.payload?.result || []),
              ],
            }
            : {}),
        },
      };
    case types.CLIENTS_IN_CASE_RECEIVE:
      return {
        ...state,
        clients_in_case_list: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
              result: [
                ...(state.list.result || []),
                ...(action.payload?.result || []),
              ],
            }
            : {}),
        },
      };
    case types.GET_SINGLE_CLIENT_RECEIVE:
      return {
        ...state,
        single_client: action.payload,
      }
    case types.CLIENT_TYPE:
      return {
        ...state,
        client_type: action.payload,
      }
    case types.GET_CLIENT_STATUS_RECEIVE:
      return {
        ...state,
        client_status_list: action.payload,
      }
    case types.GET_SINGLE_CONTACT:
      return {
        ...state,
        single_contact: action.payload,
      }
    case types.GET_CLIENT_STATUS_COUNT_RECEIVE:
      return {
        ...state,
        client_status_count: action.payload,
      }
    case types.GET_CLIENT_ATTACHMENTS_RECEIVE:
      return {
        ...state,
        client_attachments: action.payload,
      }
    default:
      return state;
  }
}
