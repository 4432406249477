import * as types from "./types";
import { caseFilter } from "../../utils/Constants";
const INITIAL_STATE = {
  newContent: {
    apiFormat: {
      case: {},
    },
  },
  uploadSuccess: {
    case: false,
    files: false,
    companyLogo: false,
    clientPic: false,
    id: null,
  },
  list: {},
  details: {},
  caseDetails: {},
  casesCounts: {},
  caseAdminList: {},
  caseHistoryList: [],
  casePartiesList: [],
  singleCase: {},
  statistics: [],
  filterKey: {},
  cardsKey: {},
  caseRelations: {},
  remindersList: [],
};

export default function cases(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_NEW_CASE_CONTENT:
      return Object.keys(action.payload).length
        ? {
            ...state,
            newContent: {
              ...state.newContent,
              ...action.payload,
              apiFormat: {
                ...state.newContent.apiFormat,
                ...action.payload.apiFormat,
                ...(action.payload.apiFormat.case
                  ? {
                      case: {
                        ...state.newContent.apiFormat.case,
                        ...action.payload.apiFormat.case,
                      },
                      personType: action.payload.apiFormat.personType,
                    }
                  : {}),
              },
            },
          }
        : {
            ...state,
            newContent: {
              apiFormat: {
                case: {},
              },
            },
          };
    case types.ADD_UPLOAD_CASE_PART_SUCCESS:
      return {
        ...state,
        uploadSuccess: {
          ...state.uploadSuccess,
          ...action.payload,
        },
      };
    case types.ADD_CASES_LIST:
      return {
        ...state,
        list: {
          ...action.payload,
        },
      };
    case types.ADD_CASE_DETAILS:
      return {
        ...state,
        details: {
          ...action.payload,
        },
      };
      case types.ADD_CASE_DETAILS_V2:
        return {
          ...state,
          caseDetails: {
            ...action.payload,
          },
        };
    case types.ADD_CASES_TASKS_COUNTS:
      return {
        ...state,
        casesCounts: {
          ...action.payload,
        },
      };
    case types.ADD_CASE_ADMIN_LIST:
      return {
        ...state,
        caseAdminList: {
          ...action.payload,
          ...(action.payload.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.caseAdminList.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },
      };
    case types.CASE_HISTORY_LIST_RECIEVE:
      return {
        ...state,
        caseHistoryList: {
          ...action.payload,
        },
      };
    case types.CASE_PARTIES_LIST_RECIEVE:
      return {
        ...state,
        casePartiesList: action.payload,
      };
    case types.ADD_CASE:
      return {
        ...state,
        singleCase: {
          ...action.payload,
        },
      };
    case types.ADD_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.payload,
      };
    case types.RESET_FILTER_VALUES:
      return {
        ...state,
        filterValues: INITIAL_STATE.filterValues,
      };
    case types.ADD_CASES_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    case types.ADD_FILTER_VALUE:
      return {
        ...state,
        filterKey: action.payload,
      };
    case types.ADD_FILTER_BY_CARDS_VALUES:
      return {
        ...state,
        cardsKey: action.payload,
      };
      case types.GET_RELATIONS_RESPONSE:
        return {
          ...state,
          caseRelations: action.payload,
        };
        case types.REMINDERS_LIST:
          return {
            ...state,
            remindersList: action.payload,
          };
    default:
      return state;
  }
}
