import { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setNavHeight } from "../../store/Loader/actions";
import ButtonComponent from "../../components/Controls/Button"
import whiteVerticalLogo from "../../assets/images/main/white-logo-mustand.svg";
import x_mark from "../../assets/images/icons/X-mark.png";
import './style.scss'

const DownloadAppPopup = ({ intl: { messages } }) => {
    const dispatch = useDispatch();
   
    const minWidth = 768;
    // Use a regular expression to test the userAgent string for common mobile device identifiers.
    const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobileDeviceView = regex.test(navigator.userAgent)
    const [isMobileDevice, setIsMobileDevice] = useState(isMobileDeviceView && (window.innerWidth < minWidth || window.screen.width < minWidth) ? true : false);

    return (

        isMobileDevice ?
            <div className="DownloadAppWrapper bg-primaryColor row justify-content-between align-items-center" id='DownloadAppPopup'>
                <div className="d-flex align-items-center col-8">
                    <img src={whiteVerticalLogo} alt='newWhiteLogo' width={'40'} />
                    <p className="mb-0 px-2">{messages.landingPage.navbar.downloadApp}</p>
                </div>
                <div className="col-4 justify-content-end d-flex align-items-center">
                    <a href="https://onelink.to/5eygzn" download>
                        <ButtonComponent
                            content={messages.actions.download}
                            minWidth={'50px'}
                            bgColorKey={'white'}
                            className={'blue p-2'}
                        />
                    </a>
                    <img src={x_mark} alt='' onClick={() => {
                        setIsMobileDevice(false);
                        dispatch(setNavHeight(60))
                        }} className="close cursor-pointer" />
                </div>
            </div>
            : ''

    )
}
export default injectIntl(DownloadAppPopup);