import { usersFilter } from "../../utils/Constants";
import * as types from "./types";

const INITIAL_STATE = {
  assignedCaseMembers: {result: []},
  nonAssignedCaseMembers: {},
  searchValue: "",
  filterBy: "",
};

export default function teamMembers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ASSIGNED_CASE_MEMBERS_RECIEVE:
      return {
        ...state,
        assignedCaseMembers:  {
          ...action.payload,
          ...(action.payload?.pagination?.current_page !== 1
            ? {
                result: [
                  ...(state.assignedCaseMembers?.result || []),
                  ...(action.payload?.result || []),
                ],
              }
            : {}),
        },

      };
    case types.NON_ASSIGNED_CASE_MEMBERS_RECIEVE:
      return {
        ...state,
        nonAssignedCaseMembers: {
          ...action.payload,
          ...(action.payload.pagination &&
          action.payload.pagination.current_page !== 1
            ? {
                result: [
                  ...(state.nonAssignedCaseMembers.result || []),
                  ...(action.payload.result || []),
                ],
              }
            : {}),
        },
      };
    case types.ADD_USERS_SEARCH_VALUE:
      return { ...state, searchValue: action.payload };
    case types.ADD_USERS_FILTER_BY:
      return { ...state, filterBy: action.payload };
    default:
      return state;
  }
}
